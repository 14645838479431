<template>
  <div>
    <tablepop  :url_type="url_type" />
  </div>
</template>

<script>
import tablepop from "../../components/table_pop";
export default {
  components: { tablepop },
  data() {
    return {
      url_type: "",
    };
  },
  created() {
    let { url_type } = this.$route.meta;
    this.url_type = url_type;
  },
};
</script>

<style>
</style>