<template>
  <div class="block">
    <div class="title">
      <p>标题：{{ info.flow_name }}</p>
      <p>详情：{{ info.flow_detail }}</p>
      <p>发起时间：{{ info.createtime }}</p>
    </div>
    <el-timeline>
      <el-timeline-item placement="top">
        <el-card>
          <h4>开始</h4>
        </el-card>
      </el-timeline-item>

      <el-timeline-item
        v-for="(item, index) in lines"
        :key="index"
        :timestamp="item.complete_time"
        placement="top"
      >
        <el-card>
          <h4>{{ item.comment }}</h4>
          <p>{{ item.name }}</p>
        </el-card>
      </el-timeline-item>

      <el-timeline-item placement="top">
        <el-card>
          <h4>结束</h4>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import { flowenginedetail } from "../../assets/request/api";
export default {
  data() {
    return {
      lines: [],
      info: "",
      inquire_url: "",
    };
  },
  methods: {
    way_show(id) {
      flowenginedetail({ instance_id: id }).then((res) => {
        let { info, lines } = res.data;
        this.lines = lines;
        this.info = info;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.block {
  height: 50vh;
  overflow-y: scroll;
}
.title {
  // display: flex;
  // justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
  p {
    margin-bottom: 10px;
  }
}
</style>
