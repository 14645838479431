import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);



export default new Vuex.Store({
  state: {
    //是否展开导航栏
    isCollapse: false,
    //上传接口
    //图片
    uploadImage: "/api/Uploadify/uploadImage",
    //文件
    uploadFile: "/api/uploadify/uploadFile",
    uploadVideo: "/api/Uploadify/uploadVideo",
    //面包屑导航
    crumbs: [],
    //菜单栏数据 
    addRouters: [],

  },
  mutations: {
    //更改导航栏展开状态
    on_isCollapse(state) {
      state.isCollapse = !state.isCollapse
    },
    //更改面包屑
    on_crumbs(state, crumbs) {
      state.crumbs = crumbs
    },
  },
  actions: {},
});
