<template>
  <header class="heasty">
    <div class="message">
      <div class="navigation">
        <i style="font-size: 20px;" :class="$store.state.isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'
          " @click="on_isCollapse"></i>
        <div>
          <el-breadcrumb>
            <el-breadcrumb-item v-for="(item, index) in this.$store.state.crumbs" :key="index">{{ item
            }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div style="display: flex">
        <el-dropdown trigger="click">
          <div class="personage">
            <p class="p1" v-if="base.tips">!{{ base.tips }}</p>

            <img :src="base.avatar ? base.avatar : require('../assets/img/tx.jpeg')
              " alt="" />

            <span>{{ base.nickname }}</span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <div class="material">
              <div class="characters">
                <img :src="base.avatar ? base.avatar : require('../assets/img/tx.jpeg')
                  " alt="" />

                <p class="p1">{{ base.nickname }}</p>
                <p>{{ base.department_name }} - {{ base.user_type_tips }}</p>
              </div>
              <div class="material-status">
                <div v-for="(item, index) in userList" :key="index">
                  <p>{{ item.department_name }}</p>
                  <p class="p1" v-if="base.id == item.id">切换</p>
                  <p @click="on_item(item)" class="p2" v-else>切换</p>
                </div>
              </div>
              <div class="material-operation">
                <el-button @click="on_mima" type="success">修改密码</el-button>
                <el-button @click.native="on_logout" icon="el-icon-close" type="danger">退出登录</el-button>
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- <div class="path">
      <el-tabs
        v-model="editableTabsValue"
        type="card"
        closable
        @tab-remove="removeTab"
      >
        <el-tab-pane
          v-for="item in editableTabs"
          :key="item.name"
          :label="item.title"
          :name="item.name"
        >
        </el-tab-pane>
      </el-tabs>
    </div> -->
  </header>
</template>

<script>
import { logout, getuserInfo, userchangeUser } from "../assets/request/api";
export default {
  data() {
    return {
      base: "",
      userList: [],
      breadList: [],
      //标签导航
      editableTabsValue: "2",
      editableTabs: [
        {
          title: "Tab 1",
          name: "1",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "2",
          content: "Tab 2 content",
        },
        {
          title: "Tab 1",
          name: "3",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "4",
          content: "Tab 2 content",
        },
        {
          title: "Tab 1",
          name: "5",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "6",
          content: "Tab 2 content",
        },
        {
          title: "Tab 1",
          name: "7",
          content: "Tab 1 content",
        },
        {
          title: "Tab 1",
          name: "8",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "9",
          content: "Tab 2 content",
        },
        {
          title: "Tab 1",
          name: "10",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "11",
          content: "Tab 2 content",
        },
        {
          title: "Tab 1",
          name: "12",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "13",
          content: "Tab 2 content",
        },
        {
          title: "Tab 1",
          name: "14",
          content: "Tab 1 content",
        },
        {
          title: "Tab 1",
          name: "15",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "16",
          content: "Tab 2 content",
        },
        {
          title: "Tab 1",
          name: "17",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "18",
          content: "Tab 2 content",
        },
        {
          title: "Tab 1",
          name: "19",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "20",
          content: "Tab 2 content",
        },
        {
          title: "Tab 1",
          name: "21",
          content: "Tab 1 content",
        },
        {
          title: "Tab 1",
          name: "22",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "23",
          content: "Tab 2 content",
        },
        {
          title: "Tab 1",
          name: "24",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "2",
          content: "Tab 2 content",
        },
        {
          title: "Tab 1",
          name: "27",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "2",
          content: "Tab 2 content",
        },
        {
          title: "Tab 1",
          name: "29",
          content: "Tab 1 content",
        },
        {
          title: "Tab 1",
          name: "29",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "30",
          content: "Tab 2 content",
        },
        {
          title: "Tab 1",
          name: "31",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "32",
          content: "Tab 2 content",
        },
        {
          title: "Tab 1",
          name: "41",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "42",
          content: "Tab 2 content",
        },
        {
          title: "Tab 1",
          name: "51",
          content: "Tab 1 content",
        },
        {
          title: "Tab 1",
          name: "52",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "62",
          content: "Tab 2 content",
        },
        {
          title: "Tab 1",
          name: "61",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "72",
          content: "Tab 2 content",
        },
        {
          title: "Tab 1",
          name: "71",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "82",
          content: "Tab 2 content",
        },
        {
          title: "Tab 1",
          name: "81",
          content: "Tab 1 content",
        },
      ],
      tabIndex: 2,
    };
  },
  created() {
    let base = sessionStorage.getItem("base");
    let userList = sessionStorage.getItem("userList");
    this.userList = userList && JSON.parse(userList);
    this.base = base && JSON.parse(base);
  },
  methods: {
    removeTab() { },
    on_isCollapse() {
      this.$store.commit("on_isCollapse");
    },
    on_retreat() {
      this.$router.go(-1);
    },
    on_item(item) {
      let uid = item.id;
      let that = this;
      userchangeUser({ uid }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          let { data } = res;
          let { token } = res.data;
          localStorage.setItem("token", token);
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("personage", JSON.stringify(data));
          getuserInfo().then((res) => {
            let { base, menu, userList } = res.data;
            sessionStorage.setItem("base", JSON.stringify(base));
            sessionStorage.setItem("menu", JSON.stringify(menu));
            sessionStorage.setItem("userList", JSON.stringify(userList));

            that.$message({
              message: "切换成功",
              type: "success",
            });
            setTimeout(function () {
              that.$router.go(0);
            }, 1000);
          });
        } else {
          that.$message.error(res.msg);
        }
      });
    },
    on_mima() {
      this.$router.push({
        path: "/retrievepass",
      });
    },
    //退出登录
    on_logout() {
      logout().then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "退出成功！",
            type: "success",
          });
          this.$tools.Clear_information();
          this.$router.replace({ path: "/login" });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.material {
  width: 300px;
  height: 280px;
  background: #f9f9f9;

  .characters {
    padding-top: 20px;
    text-align: center;
    background: #1e90fc;
    width: 100%;
    height: 150px;

    p {
      margin-top: 5px;
      font-size: 12px;
      color: #ffffff;
    }

    .p1 {
      font-size: 16px;
    }

    img {
      width: 60px;
      height: 60px;
      border-radius: 30px;
    }
  }

  .material-status {
    height: 50px;
    border-bottom: 1px solid #f5f5f5;
    background: #ffffff;
    padding: 0 5px;

    &>div {
      margin: 5px;
      display: flex;
      justify-content: space-between;
    }

    p {
      font-size: 14px;
    }

    .p1 {
      cursor: pointer;
      color: #a9acaf;
    }

    .p2 {
      cursor: pointer;
      color: #57aaff;
    }
  }

  .material-operation {
    margin: 13px 20px;
    display: flex;
    justify-content: space-between;
  }
}

.heasty {
  min-width: 1000px;
}

.message {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f3f4f5;
  padding: 0 20px;
  border-left: 1px solid #f3f4f5;
}

.path {
  background: #f5f7f9;
  border-left: 1px solid #f3f4f5;
  padding: 0 5px;

  /deep/ .el-tabs__item {
    background: #ffffff;
    border: none !important;
    margin: 0 5px;
  }

  /deep/ .el-tabs__header {
    border: none !important;
  }

  /deep/ .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: none !important;
  }
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

.personage {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 15px;

  img {
    margin-right: 5px;
    width: 24px;
    height: 24px;
    border-radius: 12px;
  }

  .p1 {
    font-weight: 400;
    font-size: 14px;
    color: #a34a4a;
    margin-right: 10px;
  }
}

.navigation {
  display: flex;
  align-items: center;

  i {
    margin-right: 20px;
  }
}

.div-right {
  margin-right: 40px;
}

/deep/ .el-tabs__header {
  margin: 0;
  padding-top: 5px;
}

.el-tabs__item {
  margin-left: 10px;
}


.el-dropdown-menu {
  padding: 0;
  border: 0;
  overflow: hidden;
}</style>
