<template>
  <div :class="this.$store.state.isCollapse ? 'dinone' : ''">
    <template v-for="(menu, index) in this.menuData">
      <el-submenu :key="menu.index" :index="index + ''" v-if="menu.items">
        <template slot="title">
          <svg v-if="menu.icon" class="icon iconfont" aria-hidden="true">
            <use :xlink:href="'#' + menu.icon"></use>
          </svg>
          <span slot="title">{{ menu.name }}</span>
        </template>
        <menu-tree :menuData="menu.items"></menu-tree>
      </el-submenu>
      <el-menu-item @click="on_router(menu)" :key="menu.url" :index="menu.url" v-else>
        <span slot="title">{{ menu.name }}</span>
      </el-menu-item>
    </template>
  </div>
</template>
 
<script>
export default {
  props: ["menuData"],
  name: "MenuTree",
  methods: {
    on_router(el) {
      let { path } = this.$route;
      if (path != el.url) {
        this.$router.push(el.url);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.iconfont {
  margin: 0 20px 0 8px;
  color: black;
}

/*隐藏文字*/
.el-menu--collapse .el-submenu__title span {
  display: none;
}

/*隐藏 > */
.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}

.dinone /deep/ .el-submenu__icon-arrow {
  display: none;
}

/deep/ .el-submenu__title:hover {
  background: #4f7afd !important;
  color: #fff !important;

  i {
    color: #ffffff;
  }
}

.el-submenu__title.is-active {
  background: #4f7afd !important;
  color: #fff !important;
}

.el-menu-item:hover {
  background: #4f7afd !important;
  color: #fff !important;
}

.el-menu-item.is-active {
  background: #4f7afd !important;
  color: #fff !important;
}
</style>