<template>
  <div class="upload">
    <el-upload
      v-if="video_List.length == 0"
      list-type="picture-card"
      :action="this.$store.state.uploadVideo"
      :headers="this.$tools.config()"
      :data="{
        path_name: 'danger_icon',
      }"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <!-- <video
        class="upload-sty"
        controls="controls"
        v-if="video_List.length != 0"
        :src="video_List"
      ></video> -->

      <i slot="default" class="el-icon-plus"></i>
    </el-upload>
    <video
      class="upload-sty"
      controls="controls"
      v-else
      :src="video_List"
    ></video>
  </div>
</template>

<script>
export default {
  model: {
    prop: "video_List",
    event: "changeData",
  },
  props: ["video_List"],
  data() {
    return {};
  },
  methods: {
    handleAvatarSuccess(res) {
      //   this.video_List = res.data.url;
      this.video_List.push(res.data.url);
      this.$tools.close_open();
    },
    //上传限制
    beforeAvatarUpload(file) {
      console.log(file.type);
      const isLt2M = file.size / 1024 / 1024 < 100;
      const isJPG = file.type === "video/mp4";

      if (!isJPG) {
        this.$message.error("上传图片只能是 MP4格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 100MB!");
      }
      if (isJPG && isLt2M) {
        this.$tools.shade_open();
      }
      return isJPG && isLt2M;
    },
    on_deleteimg(index) {
      this.img_List.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.upload {
  display: flex;
  flex-wrap: wrap;
}
/deep/ .el-upload--picture-card {
  line-height: 120px;
  width: 120px;
  height: 120px;
}
.upload-sty {
  width: 120px;
  height: 120px;
  margin: 0 5px 5px 0;
  position: relative;
}
</style>