<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="less">
* {
  margin: 0;

  padding: 0;
}

html {
  font-family: "微软雅黑" !important;
}

// .el-input__inner {
//   width: 200px !important;
// }

.el-form-item__label {
  text-align: left !important;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 200px !important;
}
.el-button--primary {
  background-color: #4f7afd !important;
}

.el-link.el-link--primary {
  color: #4f7afd !important;
}

.el-textarea__inner {
  min-height: 80px !important;
}

// .cell {
//   .el-tag--small {
//   color: #4f7afd !important;
// }
// }
.el-dialog {
  min-width: 800px;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

//el-cascader点击文字也让选中
.el-cascader-panel .el-radio {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  top: 10px;
  right: 10px;
}

.el-cascader-panel .el-radio__input {
  visibility: hidden;
}

.el-input--mini .el-input__inner {
  height: 31px !important;
  line-height: 32px !important;
}

.el-form-item--small .el-form-item__content,
.el-form-item--small .el-form-item__label {
  min-height: 32px;
}

.el-input.is-disabled .el-input__inner {
  background-color: #fff !important;
}

/*** 消除input元素 type="number" 时默认的 加减按钮*/

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*** 消除input元素 type="number" 时默认的 加减按钮---moz版*/
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
