<template>
  <main class="entirety">
    <aside class="navigation">
      <Navigation />
    </aside>
    <article
      :style="{
        width: $store.state.isCollapse
          ? 'calc(100vw - 70px)'
          : 'calc(100vw - 205px)',
      }"
      class="content"
    >
      <Breadcrumb />
      <div class="content-div">
        <div class="tablesty">
          <transition name="fade-transform" mode="out-in">
            <router-view :key="$route.path" />
          </transition>
        </div>
      </div>
    </article>
  </main>
</template>

<script>
import Navigation from "../components/Navigation";
import Breadcrumb from "../components/Breadcrumb";
export default {
  name: "Home",
  components: {
    Navigation,
    Breadcrumb,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.$store.commit("on_crumbs", this.$route.meta.crumbs);
  },
  methods: {},
};
</script>
<style scoped>
.navigation {
  height: 100vh;
  max-height: 100vh;
}
.content {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.entirety {
  height: 100vh;
  display: flex;
}
.navigation {
  height: 100vh;
  max-height: 100vh;
}
.content-div {
  min-width: 1000px;
  flex: 1;
  background: #f5f7f9;
  overflow-y: auto;
}
/* 
enter-active 定义进入过渡的结束状态
leave-active 定义离开过渡的结束状态
 */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

/* 
enter定义进入过渡的开始状态
 */
.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}
/* 
leave-to离场动画结束后的状态
 */
.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
