import $request from "./request";
let api = "";
//登录接口
export const login = data => $request(`${api}/index/index/login`, data, "POST");
//登录验证码图片接口
export const verify = data =>
    $request(`${api}/index/index/verify`, data, "POST");
//退出接口
export const logout = data =>
    $request(`${api}/index/index/logout`, data, "POST");
//用户信息
export const getuserInfo = data =>
    $request(`${api}/api/user/getuserInfo`, data, "POST");
//获取手机验证码
export const ForgetPwdVerifySend = data =>
    $request(`${api}/index/index/ForgetPwdVerifySend`, data, "POST");

//个人部门
export const userchangeUser = data =>
    $request(`${api}/api/user/changeUser`, data, "POST");

//导出word
export const dangerexportWord = data =>
    $request(`${api}/api/dangerous/exportword`, data, "POST");
//导出ex
export const dangerexportExl = data =>
    $request(`${api}/api/dangerous/exportExl`, data, "POST");

//提交验证码
export const indexcheckSmsVerify = data =>
    $request(`${api}/index/index/checkSmsVerify`, data, "POST");

//重置密码
export const indexForgetPwd = data =>
    $request(`${api}/index/index/ForgetPwd`, data, "POST");
//可视化大屏数据大屏
export const bisituation = data =>
    $request(`${api}/api/bi/situation`, data, "POST");
//可视化大屏安全态势
export const bibaseData = data =>
    $request(`${api}/api/bi/baseData`, data, "POST");
//获取天气
export const indexgetWeather = data =>
    $request(`${api}/index/index/getWeather`, data, "POST");

//工作台安全管控
export const contrologindex = data =>
    $request(`${api}/api/controlog/index`, data, "POST");
export const welcomeindex = data =>
    $request(`${api}/api/welcome/index`, data, "POST");
export const worktaskuserindex = data =>
    $request(`${api}/api/worktask/userindex`, data, "POST");
export const flowengineuserindex = data =>
    $request(`${api}/api/flowengine/userindex`, data, "POST");

//风险源列表
export const indexs = data => $request(`${api}/api/point/index`, data, "POST");
//风险源数据回显
export const saveInfo = data =>
    $request(`${api}/api/point/saveInfo`, data, "POST");
//风险源警示图片
export const iconindex = data =>
    $request(`${api}/api/icon/index`, data, "POST");
//风险源状态修改
export const StatusUpdate = data =>
    $request(`${api}/api/point/StatusUpdate`, data, "POST");
//风险源保存
export const pointsave = data =>
    $request(`${api}/api/point/save`, data, "POST");
//风险源删除
export const pointdelete = data =>
    $request(`${api}/api/point/delete`, data, "POST");

//风险源标签总列表
export const tagslistall = data =>
    $request(`${api}/api/tags/listall`, data, "POST");

//风险源标签列表
export const tagsindex = data =>
    $request(`${api}/api/tags/index`, data, "POST");
//风险源标签修改信息
export const tagssaveInfo = data =>
    $request(`${api}/api/tags/saveInfo`, data, "POST");
//风险源标签保存
export const tagssave = data => $request(`${api}/api/tags/save`, data, "POST");
//风险源标签删除
export const tagsdelete = data =>
    $request(`${api}/api/tags/delete`, data, "POST");
//风险源标签修改状态
export const tagsstatusupdate = data =>
    $request(`${api}/api/tags/statusupdate`, data, "POST");

//风险点状态修改
export const dangerousstatusupdate = data =>
    $request(`${api}/api/dangerous/statusupdate`, data, "POST");

//风险源警示图片信息
export const iconsaveInfo = data =>
    $request(`${api}/api/icon/saveInfo`, data, "POST");
//风险源警示图片保存
export const iconsave = data => $request(`${api}/api/icon/save`, data, "POST");
//风险源警示图片删除
export const icondelete = data =>
    $request(`${api}/api/icon/delete`, data, "POST");
//风险源警示图片修改状态
export const iconstatusupdate = data =>
    $request(`${api}/api/icon/statusupdate`, data, "POST");

//风险点
export const dangerousindex = data =>
    $request(`${api}/api/dangerous/index`, data, "POST");
//风险点信息查询接口
export const dangeroussaveInfo = data =>
    $request(`${api}/api/dangerous/saveInfo`, data, "POST");
//风险点保存
export const dangeroussave = data =>
    $request(`${api}/api/dangerous/save`, data, "POST");
//风险点删除
export const dangerousdelete = data =>
    $request(`${api}/api/dangerous/delete`, data, "POST");
//风险点详情
export const dangerousdetail = data =>
    $request(`${api}/api/dangerous/detail`, data, "POST");

//重点人群
export const personnelindex = data =>
    $request(`${api}/api/personnel/index`, data, "POST");
//重点人群查询修改
export const personnelsaveInfo = data =>
    $request(`${api}/api/personnel/saveInfo`, data, "POST");
//重点人群保存
export const personnelsave = data =>
    $request(`${api}/api/personnel/save`, data, "POST");
//重点人群删除
export const personneldelete = data =>
    $request(`${api}/api/personnel/delete`, data, "POST");
//重点人群详情
export const personneldetail = data =>
    $request(`${api}/api/personnel/detail`, data, "POST");

//重点评估详情
export const personnelestimateInfo = data =>
    $request(`${api}/api/personnel/estimateInfo`, data, "POST");

//重点评估保存
export const personnestimateSave = data =>
    $request(`${api}/api/personnel/estimateSave`, data, "POST");

//思想教育列表
export const eductionindex = data =>
    $request(`${api}/api/eduction/index`, data, "POST");
//思想教育删除
export const eductiondelete = data =>
    $request(`${api}/api/eduction//delete`, data, "POST");
//思想教育切换状态
export const eductionstatusupdate = data =>
    $request(`${api}/api/eduction/statusupdate`, data, "POST");
//思想教育修改数据查询
export const eductionsaveInfo = data =>
    $request(`${api}/api/eduction/saveInfo`, data, "POST");
//思想教育保存
export const eductionsave = data =>
    $request(`${api}/api/eduction/save`, data, "POST");
//思想教育详情
export const eductiondetail = data =>
    $request(`${api}/api/eduction/detail`, data, "POST");
//制度管理列表
export const regulationindex = data =>
    $request(`${api}/api/regulation/index`, data, "POST");
//制度管理查询
export const regulationsaveInfo = data =>
    $request(`${api}/api/regulation/saveInfo`, data, "POST");
//制度管理保存
export const regulationsave = data =>
    $request(`${api}/api/regulation/save`, data, "POST");
//制度管理删除
export const regulationdelete = data =>
    $request(`${api}/api/regulation/delete`, data, "POST");
//制度管理切换状态
export const regulationstatusupdate = data =>
    $request(`${api}/api/regulation/statusupdate`, data, "POST");
//制度管理详情
export const regulationdetail = data =>
    $request(`${api}/api/regulation/detail`, data, "POST");

//制度评估详情
export const regulationestimateInfo = data =>
    $request(`${api}/api/regulation/estimateInfo`, data, "POST");

//制度评估确认
export const regulationestimateSave = data =>
    $request(`${api}/api/regulation/estimateSave`, data, "POST");

//事件管理查询
export const casesindex = data =>
    $request(`${api}/api/cases/index`, data, "POST");
//事件管理删除
export const casesdelete = data =>
    $request(`${api}/api/cases/delete`, data, "POST");
//事件管理详情
export const casessaveInfo = data =>
    $request(`${api}/api/cases/saveInfo`, data, "POST");
//事件管理保存
export const casessave = data =>
    $request(`${api}/api/cases/save`, data, "POST");
//事件管理切换状态
export const casesstatusupdate = data =>
    $request(`${api}/api/cases/statusupdate`, data, "POST");
//事件管理详情
export const casesdetail = data =>
    $request(`${api}/api/cases/detail`, data, "POST");
//事件管理评估保存
export const casesestimateSave = data =>
    $request(`${api}/api/cases/estimateSave`, data, "POST");
//事件管理评估详情
export const casesestimateInfo = data =>
    $request(`${api}/api/cases/estimateInfo`, data, "POST");
//事件管理验收
export const casescheckpass = data =>
    $request(`${api}/api/cases/checkpass`, data, "POST");

//搜索学生列表
export const studentsearch = data =>
    $request(`${api}/api/student/search`, data, "POST");
//活动报备列表
export const activityindex = data =>
    $request(`${api}/api/activity/index`, data, "POST");
//活动报备修改
export const activitysaveInfo = data =>
    $request(`${api}/api/activity/saveInfo`, data, "POST");
//活动报备保存
export const activitysave = data =>
    $request(`${api}/api/activity/save`, data, "POST");
//活动报备删除
export const activitydelete = data =>
    $request(`${api}/api/activity/delete`, data, "POST");
//活动报备修改状态
export const activitystatusupdate = data =>
    $request(`${api}/api/activity/statusupdate`, data, "POST");
//活动报备详情
export const activitydetail = data =>
    $request(`${api}/api/activity/detail`, data, "POST");
export const activityExestimateInfo = data =>
    $request(`${api}/api/activity/estimateInfo`, data, "POST");
export const activityEstimateSave = data =>
    $request(`${api}/api/activity/estimateSave`, data, "POST");

casesestimateSave;

//隐患管理列表
export const hdangerindex = data =>
    $request(`${api}/api/hdanger/index`, data, "POST");
//隐患管理修改
export const hdangersaveInfo = data =>
    $request(`${api}/api/hdanger/saveInfo`, data, "POST");
//隐患管理保存
export const hdangersave = data =>
    $request(`${api}/api/hdanger/save`, data, "POST");
//隐患管理删除
export const hdangerdelete = data =>
    $request(`${api}/api/hdanger/delete`, data, "POST");
//隐患管理状态修改
export const hdangerstatusupdate = data =>
    $request(`${api}/api/hdanger/statusupdate`, data, "POST");
//隐患管理评估详情
export const hdangerestimateInfo = data =>
    $request(`${api}/api/hdanger/estimateInfo`, data, "POST");
//隐患管理评估保存
export const hdangerestimateSave = data =>
    $request(`${api}/api/hdanger/estimateSave`, data, "POST");
//隐患管理详情
export const hdangerdetail = data =>
    $request(`${api}/api/hdanger/detail`, data, "POST");
//隐患管理验收
export const hdangercheckpass = data =>
    $request(`${api}/api/hdanger/checkpass`, data, "POST");

//网格管理列表
export const armyindex = data =>
    $request(`${api}/api/army/index`, data, "POST");
//网格管理查询信息
export const armysaveInfo = data =>
    $request(`${api}/api/army/saveInfo`, data, "POST");
//网格管理保存
export const armysave = data => $request(`${api}/api/army/save`, data, "POST");
//网格管理删除
export const armydelete = data =>
    $request(`${api}/api/army/delete`, data, "POST");
//网格管理管控台账
export const armycontrolog = data =>
    $request(`${api}/api/army/controlog`, data, "POST");

//网格管理切换状态
export const armystatusupdate = data =>
    $request(`${api}/api/army/statusupdate`, data, "POST");
//值班管理列表
export const dutyindex = data =>
    $request(`${api}/api/duty/index`, data, "POST");
//值班管理信息
export const dutysaveInfo = data =>
    $request(`${api}/api/duty/saveInfo`, data, "POST");
//值班管理保存
export const dutysave = data => $request(`${api}/api/duty/save`, data, "POST");
//值班管理删除
export const dutydelete = data =>
    $request(`${api}/api/duty/delete`, data, "POST");
//值班管理状态切换
export const dutystatusupdate = data =>
    $request(`${api}/api/duty/statusupdate`, data, "POST");
//值班管理打卡日志
export const dutypunch = data =>
    $request(`${api}/api/duty/punch`, data, "POST");

// 违规物品
export const irregularindex = data =>
    $request(`${api}/api/irregular/index`, data, "POST");
export const irregulardelete = data =>
    $request(`${api}/api/irregular/delete`, data, "POST");
export const irregularSaveInfo = data =>
    $request(`${api}/api/irregular/saveinfo`, data, "POST");
export const irregularSave = data =>
    $request(`${api}/api/irregular/save`, data, "POST");
export const irregularTakeback = data =>
    $request(`${api}/api/irregular/takeback`, data, "POST");

//根据手机号查询信息
export const userfindUserByPhone = data =>
    $request(`${api}/api/user/findUserByPhone`, data, "POST");

//排班管理列表
export const dutyman = data => $request(`${api}/api/duty/man`, data, "POST");
//排班管理信息
export const dutymanSaveInfo = data =>
    $request(`${api}/api/duty/manSaveInfo`, data, "POST");
//排班管理保存
export const dutymanSave = data =>
    $request(`${api}/api/duty/manSave`, data, "POST");

//排班管理删除
export const dutymandelete = data =>
    $request(`${api}/api/duty/mandelete`, data, "POST");

//排班管理导入
export const dutyImport = data =>
    $request(`${api}/api/duty/Import`, data, "POST");
//重点人群导入
export const apidangerousimport = data =>
    $request(`${api}/api/dangerous/import`, data, "POST");

//风险点导入
export const personnelimport = data =>
    $request(`${api}/api/personnel/import`, data, "POST");

//值班日志列表
export const dutylogindex = data =>
    $request(`${api}/api/duty/logindex`, data, "POST");
//值班日志信息
export const dutylogsaveInfo = data =>
    $request(`${api}/api/duty/logsaveInfo`, data, "POST");
//值班日志保存
export const dutylogsave = data =>
    $request(`${api}/api/duty/logsave`, data, "POST");
//值班日志删除
export const dutylogdelete = data =>
    $request(`${api}/api/duty/logdelete`, data, "POST");
//值班日志状态切换
export const dutylogstatusupdate = data =>
    $request(`${api}/api/duty/logstatusupdate`, data, "POST");

//保存风险点任务
export const WorkInstancesave = data =>
    $request(`${api}/api/WorkInstance/save`, data, "POST");

// 评估风险点
export const dangerousestimateInfo = data =>
    $request(`${api}/api/dangerous/estimateInfo`, data, "POST");
// 保存风险点
export const dangerousestimateSave = data =>
    $request(`${api}/api/dangerous/estimateSave`, data, "POST");

//查询责任人列表
export const usersearch = data =>
    $request(`${api}api/user/search`, data, "POST");
//查询学生列表
export const studentindex = data =>
    $request(`${api}/api/student/index`, data, "POST");
//学生删除
export const studentdelete = data =>
    $request(`${api}/api/student/delete`, data, "POST");
//学生信息修改
export const studentsaveInfo = data =>
    $request(`${api}/api/student/saveInfo`, data, "POST");
//学生信息保存
export const studentsave = data =>
    $request(`${api}/api/student/save`, data, "POST");
//查询班级列表
export const banjiindex = data =>
    $request(`${api}/api/banji/index`, data, "POST");
//查询班级信息修改
export const banjisaveInfo = data =>
    $request(`${api}/api/banji/saveInfo`, data, "POST");
//更改班级状态
export const banjiStatusUpdate = data =>
    $request(`${api}/api/banji/StatusUpdate`, data, "POST");
//获取班级列表
export const banjisearch = data =>
    $request(`${api}/api/banji/search`, data, "POST");
//班级保存
export const banjisave = data =>
    $request(`${api}/api/banji/save`, data, "POST");
//删除班级
export const banjidelete = data =>
    $request(`${api}/api/banji/delete`, data, "POST");

//根据部门获取用户信息
export const usergetUserByDepart = data =>
    $request(`${api}/api/user/getUserByDepart`, data, "POST");

//专项检查
export const inspectionindex = data =>
    $request(`${api}/api/inspection/index`, data, "POST");
//专项检查信息
export const inspectionsaveInfo = data =>
    $request(`${api}/api/inspection/saveInfo`, data, "POST");

//专项检查保存
export const inspectionsave = data =>
    $request(`${api}/api/inspection/save`, data, "POST");
//专项检查删除
export const inspectiondelete = data =>
    $request(`${api}/api/inspection/delete`, data, "POST");

//附加分值列表
export const scoreindex = data =>
    $request(`${api}/api/score/index`, data, "POST");
//附加分值信息
export const scoresaveinfo = data =>
    $request(`${api}/api/score/saveinfo`, data, "POST");
//附加分值保存
export const scoresave = data =>
    $request(`${api}/api/score/save`, data, "POST");
//附加分值删除
export const scoredelete = data =>
    $request(`${api}/api/score/delete`, data, "POST");

//专项检查任务保存
export const inspectiontaskSend = data =>
    $request(`${api}/api/inspection/taskSend`, data, "POST");
export const spectiontaskSend = data =>
    $request(`${api}/api/spection/taskSend`, data, "POST");

//检查台账详情
export const inspectiontaskInfo = data =>
    $request(`${api}/api/inspection/taskInfo`, data, "POST");

//随机督察检查
export const spectionindex = data =>
    $request(`${api}/api/spection/index`, data, "POST");
//随机督察信息
export const spectionsaveInfo = data =>
    $request(`${api}/api/spection/saveInfo`, data, "POST");

//随机督察模板保存
export const spectioncontentSave = data =>
    $request(`${api}api/spection/contentSave`, data, "POST");
//随机督察保存
export const spectionsave = data =>
    $request(`${api}/api/spection/save`, data, "POST");
//随机督察删除
export const spectiondelete = data =>
    $request(`${api}/api/spection/delete`, data, "POST");

//查询部门和人员
export const usergetDepartUsers = data =>
    $request(`${api}/api/user/getDepartUsers`, data, "POST");

//专项检查模板
export const insptemplateindex = data =>
    $request(`${api}/api/insptemplate/index`, data, "POST");
//专项检查模板状态修改
export const insptemplatestatusupdate = data =>
    $request(`${api}/api/insptemplate/statusupdate`, data, "POST");

//专项检查模板保存
export const insptemplatecontentSave = data =>
    $request(`${api}/api/insptemplate/contentSave`, data, "POST");
//专项检查模板修改
export const insptemplatesaveInfo = data =>
    $request(`${api}/api/insptemplate/saveInfo`, data, "POST");

//专项检查选择模板保存
export const inspectioncontentSave = data =>
    $request(`${api}/api/inspection/contentSave`, data, "POST");

//考核周期列表
export const Calendarindex = data =>
    $request(`${api}/api/Calendar/index`, data, "POST");

//考核周期信息
export const Calendarsaveinfo = data =>
    $request(`${api}/api/Calendar/saveinfo`, data, "POST");
//考核周期保存
export const Calendarsave = data =>
    $request(`${api}/api/Calendar/save`, data, "POST");
//考核周期删除
export const Calendardelete = data =>
    $request(`${api}/api/Calendar/delete`, data, "POST");
//考核动态
export const departmentdynamic = data =>
    $request(`${api}/api/department/dynamic`, data, "POST");

//安全月评
export const evaluationindex = data =>
    $request(`${api}/api/evaluation/index`, data, "POST");

//安全月评信息
export const evaluationsaveinfo = data =>
    $request(`${api}/api/evaluation/saveinfo`, data, "POST");
//安全月评保存
export const evaluationsave = data =>
    $request(`${api}/api/evaluation/save`, data, "POST");
//安全月评删除
export const evaluationdelete = data =>
    $request(`${api}/api/evaluation/delete`, data, "POST");

//安全月评详情
export const evaluationtaskInfo = data =>
    $request(`${api}/api/evaluation/taskInfo`, data, "POST");

//安全月评任务
export const evaluationtasksend = data =>
    $request(`${api}/api/evaluation/tasksend`, data, "POST");
//月评台账
export const evaluationtask = data =>
    $request(`${api}/api/evaluation/task`, data, "POST");

//月评台账保存
export const evaluationtaskSave = data =>
    $request(`${api}/api/evaluation/taskSave`, data, "POST");

//流程模板列表
export const flowindex = data =>
    $request(`${api}/api/flow/index`, data, "POST");
//流程模板删除
export const flowflowDelete = data =>
    $request(`${api}/api/flow/Delete`, data, "POST");
//任务模板列表
export const Workschemeindex = data =>
    $request(`${api}/api/Workscheme/index`, data, "POST");
//任务模板删除
export const Workschemedelete = data =>
    $request(`${api}/api/Workscheme/delete`, data, "POST");

//任务模板保存
export const Workschemesave = data =>
    $request(`${api}/api/Workscheme/schemeSave`, data, "POST");
//任务模板修改新建
export const WorKschemesaveinfo = data =>
    $request(`${api}/api/Workscheme/saveinfo`, data, "POST");

//自定义表单保存
export const frmSavesave = data =>
    $request(`${api}/api/Workscheme/frmSave`, data, "POST");
//流程模板修改新建
export const flowsaveinfo = data =>
    $request(`${api}/api/flow/saveinfo`, data, "POST");
//保存模板
export const flowsave = data => $request(`${api}/api/flow/save`, data, "POST");
//流程图保存
export const flowschemeSave = data =>
    $request(`${api}/api/flow/schemeSave`, data, "POST");
//查询流程图
export const flowschemeInfo = data =>
    $request(`${api}/api/flow/schemeInfo`, data, "POST");
//提交审批
export const flowenginesave = data =>
    $request(`${api}/api/flowengine/save`, data, "POST");

//自定义表单可选接口字段
export const workschemefrmInfo = data =>
    $request(`${api}/api/workscheme/frmInfo`, data, "POST");
//待审批列表
export const flowenginenotDone = data =>
    $request(`${api}/api/flowengine/notDone`, data, "POST");
//待审批拒绝
export const flowenginerefuse = data =>
    $request(`${api}/api/flowengine/refuse`, data, "POST");
//待审批通过
export const flowenginenext = data =>
    $request(`${api}/api/flowengine/next`, data, "POST");
//待审批取消
export const flowenginecancel = data =>
    $request(`${api}/api/flowengine/cancel`, data, "POST");
//待审批信息
export const flowenginesaveInfo = data =>
    $request(`${api}/api/flowengine/saveInfo`, data, "POST");

//待办列表
export const WorktaskuserIndex = data =>
    $request(`${api}/api/Worktask/userIndex`, data, "POST");
//待办列表信息
export const WorktaskdealTask = data =>
    $request(`${api}/api/Worktask/detail`, data, "POST");

//定时任务查询
export const workinstanceindex = data =>
    $request(`${api}/api/workinstance/index`, data, "POST");
//任务台账
export const worktaskindex = data =>
    $request(`${api}/api/worktask/index`, data, "POST");

export const workinstancelog = data =>
    $request(`${api}/api/workinstance/log`, data, "POST");

//组织架构
export const departmentindex = data =>
    $request(`${api}/api/department/index`, data, "GET");
//组织架构信息
export const departmentsaveInfo = data =>
    $request(`${api}/api/department/saveInfo`, data, "POST");
//组织架构保存
export const departmentsave = data =>
    $request(`${api}/api/department/save`, data, "POST");
//组织架构删除
export const departmentdelete = data =>
    $request(`${api}/api/department/delete`, data, "POST");

//风险源分类
export const Dangercateindex = data =>
    $request(`${api}/api/Dangercate/index`, data, "POST");
//风险源分类
export const DangercatesaveInfo = data =>
    $request(`${api}/api/Dangercate/saveInfo`, data, "POST");
//风险源分类保存
export const Dangercatesave = data =>
    $request(`${api}/api/Dangercate/save`, data, "POST");
//风险源分类删除
export const Dangercatedelete = data =>
    $request(`${api}/api/Dangercate/delete`, data, "POST");

//角色列表
export const roleindex = data =>
    $request(`${api}/api/role/index`, data, "POST");
//角色信息
export const rolesaveInfo = data =>
    $request(`${api}/api/role/saveInfo`, data, "POST");
//角色保存
export const rolesave = data => $request(`${api}/api/role/save`, data, "POST");
//角色删除
export const roledelete = data =>
    $request(`${api}/api/role/delete`, data, "POST");
//角色切换状态
export const roleStatusUpdate = data =>
    $request(`${api}/api/role/StatusUpdate`, data, "POST");

//用户管理列表
export const useruserlist = data =>
    $request(`${api}/api/user/index`, data, "POST");
//用户信息
export const usersaveInfo = data =>
    $request(`${api}/api/user/saveInfo`, data, "POST");
//用户管理保存
export const usersave = data => $request(`${api}/api/user/save`, data, "POST");
//用户管理删除
export const userdelete = data =>
    $request(`${api}/api/user/delete`, data, "POST");
//用户状态修改
export const userStatusUpdate = data =>
    $request(`${api}/api/user/StatusUpdate`, data, "POST");

//权限列表
export const powerindex = data =>
    $request(`${api}/api/power/index`, data, "GET");
//权限列表信息
export const powersaveInfo = data =>
    $request(`${api}/api/power/saveInfo`, data, "POST");
//权限列表保存
export const powersave = data =>
    $request(`${api}/api/power/save`, data, "POST");
//权限列表删除
export const powerdelete = data =>
    $request(`${api}/api/power/delete`, data, "POST");

//根据隐患类型查询隐患源
export const hdangergetSourceByType = data =>
    $request(`${api}/api/hdanger/getSourceByType`, data, "POST");
//系统配置
export const systemconfig = data =>
    $request(`${api}/api/system/config`, data, "POST");
//系统配置保存
export const systconfigSave = data =>
    $request(`${api}/api/system/configSave`, data, "POST");
//学校管理列表
export const schoolindex = data =>
    $request(`${api}/api/school/index`, data, "POST");
//学校管理信息
export const schoolsaveinfo = data =>
    $request(`${api}/api/school/saveinfo`, data, "POST");
//学校管理保存
export const schoolsave = data =>
    $request(`${api}/api/school/save`, data, "POST");

//风险点管控台账
export const dangerouscontroLog = data =>
    $request(`${api}/api/dangerous/controLog`, data, "POST");
//重点人群管控台账
export const personnelcontroLog = data =>
    $request(`${api}/api/personnel/controLog`, data, "POST");
//思想教育管控台账
export const eductioncontroLog = data =>
    $request(`${api}/api/eduction/controLog`, data, "POST");

//制度管理管控台账
export const regulationcontroLog = data =>
    $request(`${api}/api/regulation/controLog`, data, "POST");
//事件管理管控台账
export const casescontroLog = data =>
    $request(`${api}/api/cases/controLog`, data, "POST");

//隐患管理管控台账
export const hdangercontroLog = data =>
    $request(`${api}/api/hdanger/controLog`, data, "POST");
//活动管理管控台账
export const activitycontroLog = data =>
    $request(`${api}/api/activity/controLog`, data, "POST");

//专项检查管控台账
export const inspectiontask = data =>
    $request(`${api}/api/inspection/task`, data, "POST");
//审批台账
export const flowengineindex = data =>
    $request(`${api}/api/flowengine/index`, data, "POST");

//管控台账任务类型查询
export const worktaskfinishdetail = data =>
    $request(`${api}/api/worktask/finishdetail`, data, "POST");
//审批台账详情
export const flowenginedetail = data =>
    $request(`${api}/api/flowengine/detail`, data, "POST");

//添加台账
export const personneladdrecorder = data =>
    $request(`${api}/api/personnel/addrecorder`, data, "POST");

//台账保存
export const personnelrecorderSave = data =>
    $request(`${api}/api/personnel/recorderSave`, data, "POST");

//管控信息接口拼接
export function ay_control(url, data) {
    return $request(`${api}/api/${url}/controLog`, data, "POST");
}
//管控信息新建接口
export function ay_addrecorder(url, data) {
    return $request(`${api}/api/${url}/addrecorder`, data, "POST");
}

//管控信息新建保存
export function ay_recorderSave(url, data) {
    return $request(`${api}/api/${url}/recorderSave`, data, "POST");
}

//审批和任务自定义接口
export function way_flowcode_url(url, data) {
    return $request(`${api}${url}`, data, "POST");
}

//自定义表单上传
export function formcustom(url, data) {
    return $request(`${api}${url}`, data, "POST");
}

export const getDingProcessForm = data =>
    $request(`${api}/api/workscheme/getDingProcessForm`, data, "POST");
export const getDingDepart = data =>
    $request(`${api}/api/department/getDingDepart`, data, "POST");
export const getDingDepartmentUser = data =>
    $request(`${api}/api/user/getDepartmentUser`, data, "POST");

export const getUserAndDepartment = data =>
    $request(`${api}/api/user/getUserAndDepartment`, data, "POST");

export const carIndex = data => $request(`${api}/api/car/index`, data, "POST");
export const carSaveInfo = data =>
    $request(`${api}/api/car/saveInfo`, data, "POST");
export const carDelete = data =>
    $request(`${api}/api/car/delete`, data, "POST");
export const carSave = data => $request(`${api}/api/car/save`, data, "POST");

export const carcodeIndex = data =>
    $request(`${api}/api/carcode/index`, data, "POST");
export const carCodetakeback = data =>
    $request(`${api}/api/carcode/takeback`, data, "POST");
export const carcodeDelete = data =>
    $request(`${api}/api/carcode/delete`, data, "POST");
export const carcodeSave = data =>
    $request(`${api}/api/carcode/save`, data, "POST");
export const carcodeSaveInfo = data =>
    $request(`${api}/api/carcode/saveInfo`, data, "POST");
export const getCarCodeList = data =>
    $request(`${api}/api/carcode/getAbleList`, data, "POST");
export const dangerControlDetail = data =>
    $request(`${api}/api/dangerous/getControlDetail`, data, "POST");
export const casesControlDetail = data =>
    $request(`${api}/api/cases/getControlDetail`, data, "POST");
export const activityControlDetail = data =>
    $request(`${api}/api/activity/getControlDetail`, data, "POST");
export const hdangerControlDetail = data =>
    $request(`${api}/api/hdanger/getControlDetail`, data, "POST");
export const inspectiondetail = data =>
    $request(`${api}/api/inspection/saveInfo`, data, "POST");
export const inspectionControlLog = data =>
    $request(`${api}/api/inspection/controlog`, data, "POST");
export const spectiondetail = data =>
    $request(`${api}/api/spection/saveInfo`, data, "POST");
export const spectionControlLog = data =>
    $request(`${api}/api/spection/controlog`, data, "POST");
export const inspectionControlDetail = data =>
    $request(`${api}/api/inspection/getControlDetail`, data, "POST");
export const spectionControlDetail = data =>
    $request(`${api}/api/spection/getControlDetail`, data, "POST");
export const studentSearch = data =>
    $request(`${api}/api/student/search`, data, "POST");

export const dutyPlanSaveInfo = data =>
    $request(`${api}/api/duty/plansaveinfo`, data, "POST");
export const dutyPlanSave = data =>
    $request(`${api}/api/duty/plansave`, data, "POST");
export const dutyplan = data => $request(`${api}/api/duty/plan`, data, "POST");
export const dutyplandelete = data =>
    $request(`${api}/api/duty/plandelete`, data, "POST");
export const dutyplanDetail = data =>
    $request(`${api}/api/duty/plandetail`, data, "POST");

export const caseForcastFlow = data =>
    $request(`${api}/api/cases/forcastCaseFllow`, data, "POST");
export const activityForcastFlow = data =>
    $request(`${api}/api/activity/forcastActivityFllow`, data, "POST");
export const spectionForcastFlow = data =>
    $request(`${api}/api/spection/forcastSpectionFllow`, data, "POST");

//户政管理列表
export const householdList = data =>
    $request(`${api}/api/household/getHouse`, data, "POST");
//视频查阅列表
export const videoList = data =>
    $request(`${api}/api/accessvideo/getList`, data, "POST");
//视频查阅审批
export const videoCheck = data =>
    $request(`${api}/api/accessvideo/check`, data, "POST");
//每日一善列表
export const everydayList = data =>
    $request(`${api}/api/kind/getList`, data, "POST");
//会议台账
export const ConferenceList = data =>
    $request(`${api}/api/meeting/getList`, data, "POST");
//安保需求列表
export const securityList = data =>
    $request(`${api}/api/security/getList`, data, "POST");
//培训台账
export const trainList = data =>
    $request(`${api}/api/train/getList`, data, "POST");
//调阅记录--添加结果
export const addAddres = data =>
    $request(`${api}/api/accessvideo/addres`, data, "POST");
//一呼百应列表

//户籍迁出 列表
export const censusList = data => $request(`${api}/api/zqadmin/census`, data, 'POST');
//护校铁军 列表
export const armyList = data => $request(`${api}/api/zqadmin/army`, data, 'POST');

//加班记录 列表
export const armyTList = data => $request(`${api}/api/zqadmin/armyT`, data, 'POST');

//日常巡逻 列表
export const patrolList = data => $request(`${api}/api/zqadmin/patrol`, data, 'POST');
//日常巡逻 人员列表
export const userList = data => $request(`${api}/api/zqadmin/userlist`, data, 'POST');

//日常巡逻 分配
export const patrolPost = data => $request(`${api}/api/zqadmin/patrolPost`, data, 'POST');
//下班日志 列表
export const knockoffList = data => $request(`${api}/api/zqadmin/knockoff`, data, 'POST');
// 上下班交接 列表
export const commutingList = data => $request(`${api}/api/zqadmin/commuting`, data, 'POST');

// 专项检查 列表
export const specialList = data => $request(`${api}/api/zqadmin/special`, data, 'POST');
// 专项检查 提交
export const specialPost = data => $request(`${api}/api/zqadmin/specialPost`, data, 'POST');

export const hundredList = data =>
    $request(`${api}/api/call/getList`, data, "POST");

export const stuPartrolIndex = data =>
    $request(`${api}/api/stupatrol/index`, data, "POST");
export const stuPartrolDelete = data =>
    $request(`${api}/api/stupatrol/delete`, data, "POST");
export const stuPartrolInfo = data =>
    $request(`${api}/api/stupatrol/saveInfo`, data, "POST");
export const stuPartrolSave = data =>
    $request(`${api}/api/stupatrol/save`, data, "POST");

export const stuThreeEduIndex = data =>
    $request(`${api}/api/stuthreeedu/index`, data, "POST");
export const stuThreeEduDelete = data =>
    $request(`${api}/api/stuthreeedu/delete`, data, "POST");
export const stuThreeEduInfo = data =>
    $request(`${api}/api/stuthreeedu/saveInfo`, data, "POST");

export const fileIndex = data =>
    $request(`${api}/api/file/index`, data, "POST");
export const fileDelete = data =>
    $request(`${api}/api/file/delete`, data, "POST");
export const fileInfo = data =>
    $request(`${api}/api/file/saveInfo`, data, "POST");

//会议台账归档
export const handle = data =>
    $request(`${api}/api/meeting/handle`, data, "POST");

//培训台账归档
export const trainhandle = data =>
    $request(`${api}/api/train/handle`, data, "POST");

export const UpdateState = data =>
    $request(`${api}/api/eduction/StatusUpdate`, data, "POST");

