<template>
  <div id="scroll-board">
    <div class="ranking-board-title">高风险点列表</div>
    <dv-scroll-board :config="config" />
  </div>
</template>

<script>
export default {
  name: "risk_point",
  data() {
    return {
      config: {
        header: ["时间", "病害信息", "数量", "标段"],
        data: [
          ["2021-07-01 19:25:00", "路面危害-松散"],
          ["2021-07-19 17:25:00", "路面危害-路面油污清理"],
          ["2021-08-03 16:25:00", "交安设施-交通标志牌结构"],
          ["2021-08-04 15:25:00", "路基危害-防尘网"],
          ["2021-08-08 14:25:00", "交安设施-交通标志牌结构"],
          ["2021-08-16 13:25:00", "路面危害-松散"],
          ["2021-09-07 12:25:00", "路基危害-防尘网"],
          ["2021-09-08 11:25:00", "路面危害-路面油污清理"],
          ["2021-10-09 10:25:00", "交安设施-交通标志牌结构"],
          ["2021-10-10 09:25:00", "路基危害-防尘网"],
        ],
        index: true,
        columnWidth: [50],
        align: ["center"],
        rowNum: 6,
        headerBGC: "#1981f6",
        headerHeight: 0,
        oddRowBGC: "rgba(0, 44, 81, 0.8)",
        evenRowBGC: "rgba(10, 29, 50, 0.8)",
      },
    };
  },
};
</script>

<style lang="less" scoped>
#scroll-board {
  width: 30%;
  box-sizing: border-box;
  margin-right: 20px;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, 0.5);
  display: flex;
  flex-direction: column;
}
.ranking-board-title {
  padding-left: 10px;
  font-weight: bold;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 20px;
}
</style>
