<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" :model="formLabelAlign" label-width="150px">
        <div class="div-sty" v-for="(item, index) in formData" :key="index">
          <el-form-item
            v-if="item.type == 'text' || item.type == 'textarea'"
            :label="item.label"
            :prop="item.model"
            :rules="{
              required: item.options.isrules,
              message: '请选择',
              trigger: ['change'],
            }"
          >
            <el-input
              :disabled="isdisabled"
              class="input-sty"
              :type="item.type"
              :placeholder="item.placeholder"
              v-model="formLabelAlign[item.model]"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="item.type == 'radio'"
            :label="item.label"
            :prop="item.model"
            :rules="{
              required: item.options.isrules,
              message: '请选择',
              trigger: ['change'],
            }"
          >
            <el-radio-group v-model="formLabelAlign[item.model]">
              <el-radio
                :disabled="isdisabled"
                :label="el.value"
                v-for="(el, ins) in item.options.list"
                :key="ins"
                >{{ el.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>

          <el-form-item
            v-if="item.type == 'checkbox'"
            :label="item.label"
            :prop="item.model"
            :rules="{
              required: item.options.isrules,
              message: '请选择',
              trigger: ['change'],
            }"
          >
            <el-checkbox-group :disabled="isdisabled" v-if="item.select_List">
              <el-checkbox
                v-for="(el, index) in item.select_List"
                :key="index"
                >{{ el }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>

          <!-- <el-form-item
            v-if="item.type == 'picker'"
            :label="item.label"
            :prop="item.model"
            :rules="{
              required: item.isrules,
              message: '请选择',
              trigger: ['change'],
            }"
          >
            <el-date-picker
              value-format="yyyy-MM-dd"
              :disabled="isdisabled"
              v-model="formLabelAlign[item.value]"
              type="date"
              :placeholder="item.start_name"
            >
            </el-date-picker>
          </el-form-item> -->

          <el-form-item
            v-if="item.type == 'upload_img'"
            :label="item.label"
            :prop="item.model"
            :rules="{
              required: item.options.isrules,
              message: '请选择',
              trigger: ['change'],
            }"
          >
            <div class="upload">
              <div
                v-for="(el, index) in formLabelAlign[item.model]"
                :key="index"
                class="upload-sty"
              >
                <el-image class="imgs" :src="el" closable />
                <i
                  @click="on_deleteimg(index, item.model)"
                  class="el-icon-circle-close"
                ></i>
              </div>

              <el-upload
                list-type="picture-card"
                :action="$store.state.uploadImage"
                :headers="$tools.config()"
                :data="{
                  path_name: 'danger_icon',
                }"
                :show-file-list="false"
                :on-success="(res) => handleSuccessimg(res, item.model)"
                :before-upload="beforeAvatarUploadimg"
              >
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>

          <el-form-item
            v-if="item.type == 'upload_video'"
            :label="item.label"
            :prop="item.model"
            :rules="{
              required: item.options.isrules,
              message: '请选择',
              trigger: ['change'],
            }"
          >
            <div class="upload">
              <video
                class="upload-videosty"
                controls="controls"
                v-if="formLabelAlign[item.model]"
                :src="formLabelAlign[item.model]"
              ></video>

              <el-upload
                v-else
                list-type="picture-card"
                :action="$store.state.uploadVideo"
                :headers="$tools.config()"
                :data="{
                  path_name: 'danger_icon',
                }"
                :show-file-list="false"
                :on-success="(res) => beforeAvatarUpload_video(res, item.model)"
                :before-upload="handleAvatarSuccess_video"
              >
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>

          <el-form-item
            v-if="item.type == 'upload_file'"
            :label="item.label"
            :prop="item.model"
            :rules="{
              required: item.options.isrules,
              message: '请选择',
              trigger: ['change'],
            }"
          >
            <div>
              <el-upload
                class="upload-demo"
                ref="upload"
                :action="$store.state.uploadFile"
                :headers="$tools.config()"
                :data="{
                  path_name: 'danger_icon',
                }"
                :show-file-list="false"
                :on-success="(res) => beforeAvatarUpload_file(res, item.model)"
                :before-upload="handleAvatarSuccess_file"
                list-type="picture"
              >
                <div>
                  <el-tag
                    v-for="(el, index) in formLabelAlign[item.model]"
                    :key="index"
                    closable
                    @close="on_deleteFile(index, item.model)"
                    >附件</el-tag
                  >
                </div>

                <el-button slot="trigger" size="small" type="primary"
                  >选择文件</el-button
                >
              </el-upload>
            </div>
          </el-form-item>

          <el-form-item
            v-if="item.type == 'select'"
            :label="item.name"
            :prop="item.value"
            :rules="{
              required: item.isrules,
              message: '请选择',
              trigger: ['change'],
            }"
          >
            <el-select
              :disabled="isdisabled"
              :placeholder="item.placeholder"
              v-model="formLabelAlign[item.value]"
            >
              <el-option
                v-for="(el, ins) in item.select_List"
                :key="ins"
                :label="el"
                :value="ins"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            v-if="item.type == 'switch'"
            :label="item.label"
            :prop="item.model"
            :rules="{
              required: item.options.isrules,
              message: '请选择',
              trigger: ['change'],
            }"
          >
            <el-switch
              :disabled="isdisabled"
              v-model="formLabelAlign[item.model]"
              active-value="1"
              inactive-value="2"
              :active-text="item.active_text"
              :inactive-text="item.inactive_text"
            >
            </el-switch>
          </el-form-item>
        </div>
      </el-form>
      <div v-if="!isdisabled" style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary"
          >保存</el-button
        >
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { formcustom, worktaskfinishdetail } from "../../assets/request/api";
import uploadingimg from "../../components/uploading/uploading_img.vue";
export default {
  name: "custom_Fromanalysis",
  props: ["frmcode", "formvalue"],
  components: { uploadingimg },
  data() {
    return {
      id: "",
      url: "",
      formLabelAlign: {},
      formData: [],
      isdisabled: false,
      //需要额外处理的字段
      arrdispose: [],
    };
  },

  filters: {},
  methods: {
    //解析后台返回表单数据
    way_Datadispose(id, type) {
      console.log(id);
      console.log(type);
      let { form_code, url } = this.frmcode;
      let form_codeData = JSON.parse(form_code);
      let formvalue = this.formvalue;
      this.url = url;
      this.formData = form_codeData;
      console.log(form_codeData);
      console.log(formvalue);
      let data = {};
      let arrdispose = [];
      form_codeData.map((item) => {
        switch (item.type) {
          case "switch":
            data[item.model] = formvalue ? formvalue[item.model] || "1" : "1";
            break;
          case "upload_img":
            data[item.model] = formvalue ? formvalue[item.model] || [] : [];
            arrdispose.push(item.model);
            break;
          case "upload_file":
            data[item.model] = formvalue ? formvalue[item.model] || [] : [];
            arrdispose.push(item.model);
            break;
          case "select":
            formcustom(item.options.url_site).then((res) => {
              item.pickerList = res.data;
            });
            data[item.model] = formvalue ? formvalue[item.model] || "" : "";
            break;
          default:
            data[item.model] = formvalue ? formvalue[item.model] || "" : "";
        }
      });
      console.log(data);
      this.id = id;
      this.arrdispose = arrdispose;
      this.formLabelAlign = data;
      this.formLabelAlign.task_id = id;
    },
    //图片上传方法
    beforeAvatarUploadimg(file) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/bmp";

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG ，png ，jpg ，bmp格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      if (isJPG && isLt2M) {
        this.$tools.shade_open();
      }
      return isJPG && isLt2M;
    },
    //上传图片结果处理
    handleSuccessimg(res, model) {
      this.formLabelAlign[model].push(res.data.url);
      this.$tools.close_open();
    },
    //删除图片
    on_deleteimg(index, model) {
      this.formLabelAlign[model].splice(index, 1);
    },
    //视频上传方法
    handleAvatarSuccess_video(file) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      const isJPG = file.type === "video/mp4";

      if (!isJPG) {
        this.$message.error("上传图片只能是 MP4格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      if (isJPG && isLt2M) {
        this.$tools.shade_open();
      }
      return isJPG && isLt2M;
    },

    beforeAvatarUpload_video(res, model) {
      this.formLabelAlign[model] = res.data.url;
      this.$tools.close_open();
    },

    //文件上传
    beforeAvatarUpload_file(res, model) {
      this.formLabelAlign[model].push(res.data.url);
      this.$tools.close_open();
    },
    //上传限制
    handleAvatarSuccess_file(file) {
      const isLt2M = file.size / 1024 / 1024 < 20;

      const isJPG =
        file.type === "zip" ||
        file.type === "rar" ||
        file.type === "docx" ||
        file.type === "xls" ||
        file.type === "xlsx" ||
        file.type === "pdf" ||
        file.type === "text/plain" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "doc";

      if (!isJPG) {
        this.$message.error("上传文件只能是 pdf ，doc ，xlsx ，docx格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      console.log(isJPG && isLt2M);
      if (isJPG && isLt2M) {
        this.$tools.shade_open();
      }
      return isJPG && isLt2M;
    },
    on_deleteFile(index, model) {
      this.formLabelAlign[model].splice(index, 1);
    },

    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { formLabelAlign, id, url, arrdispose } = this;
          let formLabelAlignFrom = JSON.parse(JSON.stringify(formLabelAlign));

          if (arrdispose.length != 0) {
            arrdispose.map((item) => {
              formLabelAlignFrom[item] = formLabelAlignFrom[item].join(",");
            });
          }

          formcustom(url, formLabelAlignFrom).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.route_go();
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}

.upload {
  display: flex;
  flex-wrap: wrap;
}

/deep/ .el-upload--picture-card {
  line-height: 80px;
  width: 80px;
  height: 80px;
}
.upload-sty {
  width: 80px;
  height: 80px;
  margin: 0 5px 5px 0;
  position: relative;
  .imgs {
    width: 100%;
    height: 100%;
  }
  i {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    color: red;
    font-weight: bold;
    z-index: 999;
  }
}
.upload-videosty {
  width: 160px;
  height: 160px;
  margin: 0 5px 5px 0;
  position: relative;
}
</style>