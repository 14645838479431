<template>
  <div>
    <!-- <datav /> -->
  </div>
</template>


<script>
import datav from "../../components/datav/datav/index.vue";
export default {
  components: {
    datav,
  },
  created() {
    let type = this.$route.meta.type;
    let routerData = this.$router.resolve({
      path: "/datav",
      query: {
        type,
      },
    });
    window.open(routerData.href, "_blank");
  },
};
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  height: 90vh;
}
</style>