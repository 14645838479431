<template>
  <div class="upload">
    <div v-for="(item, index) in img_List" :key="index" class="upload-sty">
      <el-image
        :z-index="9999"
        class="imgs"
        :src="item"
        closable
        :preview-src-list="[item]"
      />
      <i @click="on_deleteimg(index)" class="el-icon-circle-close"></i>
    </div>
    <el-upload
      list-type="picture-card"
      :action="this.$store.state.uploadImage"
      :headers="this.$tools.config()"
      :data="{
        path_name: 'danger_icon',
      }"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <i slot="default" class="el-icon-plus"></i>
    </el-upload>
  </div>
</template>

<script>
export default {
  model: {
    prop: "img_List",
    event: "changeData",
  },
  props: ["img_List"],
  data() {
    return {};
  },
  methods: {
    handleAvatarSuccess(res) {
      this.img_List.push(res.data.url);
      this.$tools.close_open();
    },
    //上传限制
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/bmp";

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG ，png ，jpg ，bmp格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      if (isJPG && isLt2M) {
        this.$tools.shade_open();
      }
      return isJPG && isLt2M;
    },
    on_deleteimg(index) {
      this.img_List.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.upload {
  display: flex;
  flex-wrap: wrap;
}
/deep/ .el-upload--picture-card {
  line-height: 120px;
  width: 120px;
  height: 120px;
}
.upload-sty {
  width: 80px;
  height: 80px;
  margin: 0 5px 5px 0;
  position: relative;
  .imgs {
    width: 100%;
    height: 100%;
  }
  i {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    color: red;
    font-weight: bold;
    z-index: 999;
  }
}
</style>