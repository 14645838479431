<template>
  <div id="rose-chart">
    <div class="rose-chart-title">5类人群数量统计</div>
    <dv-charts :key="key" :option="option" />
  </div>
</template>

<script>
export default {
  name: "RoseChart",
  data() {
    return {
      key: 1,
      option: {
        series: [
          // {
          //   type: "pie",
          //   roseSort: false,
          //   data: [
          //     { name: "可口可乐", value: 20 },
          //     { name: "百事可乐", value: 30 },
          //     { name: "哇哈哈", value: 50 },
          //     { name: "康师傅", value: 48 },

          //     { name: "统一", value: 2 },
          //   ],

          //   outsideLabel: {
          //     formatter: `\n {name} {percent}% `,

          //     style: {
          //       fill: "#fff",
          //     },
          //     labelLineStyle: {
          //       stroke: "#fff",
          //     },
          //   },
          //   roseType: true,
          //   radius: "70%",
          //   startAngle: Math.PI / 1,
          //   roseSort: true,
          // },

          {
            type: "pie",
            radius: "50%",
            roseSort: false,
            percentToFixed: 0,
            data: [],
            insideLabel: {
              show: false,
            },
            outsideLabel: {
              formatter: `{name} {percent}%`,
              labelLineEndLength: 20,
              style: {
                fill: "#fff",
              },
              labelLineStyle: {
                stroke: "#fff",
                lineWidth: 1,
              },
            },
            roseType: true,
            radius: "70%",
            startAngle: Math.PI / 1,
            roseSort: true,
          },
        ],
        color: ["#da2f00", "#fa3600", "#ff4411", "#ff724c", "#541200"],
      },
    };
  },
  props: {
    personnlList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    personnlList: function (newValue, oldVal) {
      this.option.series[0].data = newValue;
      console.log(this.option.series[0].data);
      this.option = { ...this.option };
    },
    deep: true,
  },
  methods: {},
  // mounted() {
  //   var _this = this;
  //   window.addEventListener("resize", function (e) {
  //     _this.$nextTick(() => {
  //       _this.key++;
        
  //       //刷新页面，重新计算高度
  //       // window.location.reload();
  //     });
  //   });
  // },
};
</script>

<style lang="less" >
#rose-chart {
  width: 40%;
  height: 100%;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, 0.5);
  box-sizing: border-box;

  .rose-chart-title {
    height: 50px;
    font-weight: bold;
    text-indent: 20px;
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  .dv-charts-container {
    height: calc(~"100% - 50px");
  }
}
</style>
