<template>
  <div id="cards">
    <div class="bc-chart-item">
      <div class="bcci-header">{{ config0_name }}</div>
      <dv-active-ring-chart :config="config0" />
      <Label-Tag :config="labelConfig" />
    </div>
    <dv-decoration-2 class="decoration-1" :reverse="true" style="width: 5px" />
    <div class="bc-chart-item">
      <div class="bcci-header">{{ config1_name }}</div>
      <dv-active-ring-chart :config="config1" />
      <Label-Tag :config="labelConfig" />
    </div>

    <dv-decoration-2 class="decoration-2" :reverse="true" style="width: 5px" />

    <div class="bc-chart-item">
      <div class="bcci-header">{{ config2_name }}</div>
      <dv-active-ring-chart :config="config2" />
      <Label-Tag :config="labelConfig" />
    </div>

    <dv-decoration-2 class="decoration-3" :reverse="true" style="width: 5px" />

    <div class="bc-chart-item">
      <div class="bcci-header">{{ config3_name }}</div>
      <dv-active-ring-chart :config="config3" />
      <Label-Tag :config="labelConfig" />
    </div>
    <dv-decoration-2 class="decoration-3" :reverse="true" style="width: 5px" />

    <div class="bc-chart-item">
      <div class="bcci-header">{{ config4_name }}</div>
      <dv-active-ring-chart :config="config4" />
      <Label-Tag :config="labelConfig" />
    </div>
  </div>
</template>

<script>
import LabelTag from "./LabelTag";

export default {
  name: "BottomCharts",
  components: {
    LabelTag,
  },
  props: {
    dangerCateColor: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    dangerCateColor: function (newValue, oldVal) {
      newValue.map((item, index) => {
        this[`config${index}`].data = item.data;
        this[`config${index}_name`] = item.cate_name;
      });
      this.config0 = { ...this.config0 };
      this.config1 = { ...this.config1 };
      this.config2 = { ...this.config2 };
      this.config3 = { ...this.config3 };
      this.config4 = { ...this.config4 };
    },
    deep: true,
  },
  data() {
    return {
    
      config0_name: "",
      config0: {
        data: [
          {
            name: "I(红色)",
            value: 615,
          },
          {
            name: "II(橙色)",
            value: 322,
          },
          {
            name: "III(黄色)",
            value: 198,
          },
          {
            name: "IV(蓝色)",
            value: 80,
          },
        ],
        color: ["#F6580E", "#FD9133", "#F6D101", "#2E8CFF"],
        radius: "65%",
        activeRadius: "70%",
      },
      config1_name: "",

      config1: {
        data: [
          {
            name: "I(红色)",
            value: 615,
          },
          {
            name: "II(橙色)",
            value: 322,
          },
          {
            name: "III(黄色)",
            value: 198,
          },
          {
            name: "IV(蓝色)",
            value: 80,
          },
        ],
        color: ["#F6580E", "#FD9133", "#F6D101", "#2E8CFF"],

        radius: "65%",
        activeRadius: "70%",
      },
      config2_name: "",

      config2: {
        data: [
          {
            name: "I(红色)",
            value: 615,
          },
          {
            name: "II(橙色)",
            value: 322,
          },
          {
            name: "III(黄色)",
            value: 198,
          },
          {
            name: "IV(蓝色)",
            value: 80,
          },
        ],
        color: ["#F6580E", "#FD9133", "#F6D101", "#2E8CFF"],
        radius: "65%",
        activeRadius: "70%",
      },
      config3_name: "",

      config3: {
        data: [
          {
            name: "I(红色)",
            value: 615,
          },
          {
            name: "II(橙色)",
            value: 322,
          },
          {
            name: "III(黄色)",
            value: 198,
          },
          {
            name: "IV(蓝色)",
            value: 80,
          },
        ],
        color: ["#F6580E", "#FD9133", "#F6D101", "#2E8CFF"],

        radius: "65%",
        activeRadius: "70%",
      },
      config4_name: "",

      config4: {
        data: [
          {
            name: "I(红色)",
            value: 615,
          },
          {
            name: "II(橙色)",
            value: 322,
          },
          {
            name: "III(黄色)",
            value: 198,
          },
          {
            name: "IV(蓝色)",
            value: 80,
          },
        ],
        color: ["#F6580E", "#FD9133", "#F6D101", "#2E8CFF"],

        radius: "65%",
        activeRadius: "70%",
      },

      labelConfig: {
        data: ["I(红色)", "II(橙色)", "III(黄色)", "IV(蓝色)"],
      },
    };
  },
};
</script>

<style lang="less">
#cards {
  display: flex;
  justify-content: space-between;
  height: 57%;

  .bc-chart-item {
    width: 25%;
    height: 100%;
    padding-top: 15px;
    box-sizing: border-box;
    background-color: rgba(6, 30, 93, 0.5);
    border-top: 2px solid rgba(1, 153, 209, 0.5);
  }

  .bcci-header {
    height: 50px;
    text-align: center;
    line-height: 30px;
    font-size: 20px;
  }

  .dv-active-ring-chart {
    height: calc(~"100% - 120px");
  }

  .label-tag {
    height: 60px;
  }

  .active-ring-name {
    font-size: 18px !important;
  }

  .decoration-1,
  .decoration-2,
  .decoration-3 {
    display: absolute;
    left: 0%;
  }
}
</style>
