<template>
  <div>
    <el-form ref="formName" label-width="180px" :model="formLabelAlign">
      <el-form-item label="详情：">
        <el-input
          type="textarea"
          placeholder="请输入详情"
          size="mini"
          class="form-textarea"
          v-model="formLabelAlign.form_detail"
        ></el-input>
      </el-form-item>
      <el-form-item label="现场图片：">
        <uploadingimg v-model="formLabelAlign.img_List" />
      </el-form-item>
      <el-form-item label="视频或录音：">
        <uploadingVideo v-model="formLabelAlign.video_List" />
      </el-form-item>

      <el-form-item label="结果评价：" v-if="url_type == 'personnel'">
        <el-input
          type="textarea"
          placeholder="请输入结果评价"
          size="mini"
          class="form-textarea"
          v-model="formLabelAlign.form_detail"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="是否完成："
        v-if="url_type == 'hdanger' || url_type == 'cases'"
      >
        <el-switch
          v-model="formLabelAlign.form_is_normal"
          active-text="是"
          inactive-text="否"
          active-value="1"
          inactive-value="2"
        >
        </el-switch>
      </el-form-item>

      <el-form-item label="是否正常：" v-else>
        <el-switch
          v-model="formLabelAlign.form_is_normal"
          active-text="正常"
          inactive-text="异常"
          active-value="1"
          inactive-value="2"
        >
        </el-switch>
      </el-form-item>
    </el-form>

    <div style="text-align: center; margin: 20px 0">
      <el-button @click="on_preserve('formName')" type="primary"
        >确定</el-button
      >
      <el-button @click="route_go">返回</el-button>
    </div>
  </div>
</template>

<script>
import { ay_recorderSave } from "../../assets/request/api";
import uploadingimg from "../../components/uploading/uploading_img.vue";
import uploadingVideo from "../../components/uploading/uploading_Video.vue";
export default {
  data() {
    return {
      url_type: "",
      formLabelAlign: {
        source_id: "",
        form_detail: "",
        video_List: [],
        img_List: [],
        form_is_normal: "1",
      },
    };
  },
  components: {
    uploadingimg,
    uploadingVideo,
  },

  methods: {
    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formLabelAlign = JSON.parse(JSON.stringify(this.formLabelAlign));
          formLabelAlign.vedio = formLabelAlign.video_List.join(",");
          formLabelAlign.images = formLabelAlign.img_List.join(",");
          delete formLabelAlign.video_List;
          delete formLabelAlign.img_List;
          let url_type = this.url_type;
          ay_recorderSave(url_type, formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.route_go();
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
};
</script>
<style lang="less" scoped>
.title {
  margin-bottom: 10px;
  font-size: 14px;
  p {
    margin-bottom: 10px;
  }
}
</style>
