<template>
  <div>
    <el-cascader
      filterable
      :show-all-levels="false"
      style="width: 100%"
      :value="bepartment_List"
      :options="bepartment_Data"
      :props="{
        checkStrictly: true,
        label: 'name',
        children: 'items',
        value: 'id',
      }"
      @change="dataChange"
      placeholder="请选择部门"
    ></el-cascader>
  </div>
</template>

<script>
import { departmentindex } from "../assets/request/api";
export default {
  name: "Bepartment",
  model: {
    prop: "department_id",
    event: "changeData",
  },
  props: ["department_id"],
  data() {
    return {
      bepartment_Data: [],
      bepartment_List: [],
    };
  },
  created() {
    this.way_departmentindex();
  },
  methods: {
    //选择信息后
    dataChange(event) {
      this.bepartment_List = event;
      let events = event[event.length - 1];
      this.$emit("changeData", events);
    },
    //部门信息
    way_departmentindex() {
      departmentindex().then((res) => {
        let { data, enable_list } = res.data;
        this.bepartment_Data = data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>