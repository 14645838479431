import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from "element-ui";
import less from 'less'
import "element-ui/lib/theme-chalk/index.css";
import './assets/css/base.css'
import tools from './assets/js/tools'
import "./assets/img/Unicode/iconfont.css"
import dataV from '@jiaminghi/data-view'
import './assets/img/Unicode/iconfont.js'

Vue.config.productionTip = false;

Vue.use(dataV)

Vue.use(Element, { size: 'small', zIndex: 3000 });
Vue.use(less)
Vue.use(Element);
Vue.prototype.$tools = tools
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
