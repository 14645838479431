<template>
  <div class="navigation-the">
    <div class="navigation-top" :class="{ navigation_left: !$store.state.isCollapse }">
      <img src="../assets/img/logo.png" alt="" />
      <!-- <p v-if="!$store.state.isCollapse" class="title">平安经院</p> -->
      <p v-if="!$store.state.isCollapse" class="title">平安经院</p>
    </div>
    <el-menu :default-active="act" :collapse="this.$store.state.isCollapse" class="el-menu-vertical-demo naviheigth"
      background-color="#FFFFFF" text-color="#333333" @select="handleMenuSelect" active-text-color="#4F7AFD"
      unique-opened>
      <menu-tree :menuData="addRouters"></menu-tree>
    </el-menu>
  </div>
</template>

<script>
import MenuTree from "./MenuTree";
export default {
  name: "Navigation",
  components: {
    MenuTree,
  },
  created() {
    let menu = sessionStorage.getItem("menu");
    if (menu) {
      this.addRouters = JSON.parse(menu);
    } else {
      this.$message({
        message: "请重新登录！",
        type: "success",
      });
      this.$router.replace({ path: "/login" });
    }
  },
  mounted() {
    this.act = this.$route.path;
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.$store.commit("on_crumbs", this.$route.meta.crumbs);
        this.act = this.$route.path;
      },
      deep: true,
    },
  },
  data() {
    return {
      act: "",
      isCollapse: true,
      //动态生成的路右边信息
      addRouters: [
        {
          name: "工作台",
          index: "19",
          icon: "icon-computer-fill-copy",
          children: [
            {
              index: "1-1",
              name: "首页",
              path: "/workbench/work_page",
            },
            // {
            //   index: "1-2",
            //   name: "可视化页面",
            //   path: "/workbench/visual_page",
            // },

            {
              index: "1-2",
              name: "安全态势",
              path: "/workbench/safety_large",
            },
            {
              index: "1-3",
              name: "数据大屏",
              path: "/workbench/data_large",
            },
          ],
        },
        {
          name: "风险点管控",
          index: "0",
          icon: "icon-building-fill",
          children: [
            {
              index: "1-2",
              name: "风险点",
              path: "/security/risk_point",
            },

            {
              index: "1-18",
              name: "管控台账",
              path: "/security/control_standing",
            },
          ],
        },

        {
          name: "重点人群",
          index: "9",
          icon: "icon-user-group-fill",
          children: [
            {
              index: "1-3",
              name: "重点人群",
              path: "/security/focus_groups",
            },
            {
              index: "1-2",
              name: "管控台账",
              path: "/security/emphasie_standing",
            },
          ],
        },

        {
          name: "安全宣教",
          index: "10",
          icon: "icon-edit2-fill",
          children: [
            {
              index: "1-4",
              name: "安全宣教",
              path: "/security/thought_education",
            },
            {
              index: "1-2",
              name: "管控台账",
              path: "/security/thought_standing",
            },
          ],
        },

        {
          name: "制度管理",
          index: "11",
          icon: "icon-submit-fill",
          children: [
            {
              index: "1-5",
              name: "制度管理",
              path: "/security/system_administration",
            },
            {
              index: "1-2",
              name: "制度详情",
              path: "/security/system_standing",
            },
          ],
        },

        {
          name: "事件管理",
          index: "12",
          icon: "icon-anjian1",
          children: [
            {
              index: "1-6",
              name: "事件管理",
              path: "/security/case_management",
            },
            {
              index: "1-9",
              name: "管控台账",
              path: "/security/case_standing",
            },
          ],
        },

        {
          name: "活动报备",
          index: "14",
          icon: "icon-flag-copy",
          children: [
            {
              index: "1-7",
              name: "活动报备",
              path: "/security/Activities_backup",
            },
            {
              index: "1-9",
              name: "管控台账",
              path: "/security/activity_standing",
            },
          ],
        },

        {
          name: "隐患管理",
          index: "13",
          icon: "icon-alert-octagon",
          children: [
            {
              index: "1-8",
              name: "隐患管理",
              path: "/security/Risk_management",
            },
            {
              index: "1-9",
              name: "管控台账",
              path: "/security/hidden_standing",
            },
          ],
        },
        {
          name: "网格化管理",
          index: "17",
          icon: "icon-gongzuotai1",
          children: [
            {
              index: "1-9",
              name: "网格化管理",
              path: "/security/Soldier_brother",
            },
            {
              index: "1-10",
              name: "管控台账",
              path: "/security/Soldier_brothersstanding",
            },
          ],
        },

        {
          name: "值班管理",
          index: "1",
          icon: "icon-zhiban",
          children: [
            {
              index: "1-11",
              name: "排班管理",
              path: "/security/duty_arrange",
            },
            {
              index: "1-10",
              name: "班次管理",
              path: "/security/duty_management",
            },
            {
              index: "1-18",
              name: "值班日志",
              path: "/security/duty_logbook",
            },
          ],
        },

        {
          name: "考核管理",
          icon: "icon-dictionary-fill",
          index: "3",
          children: [
            // {
            //   name: "专项检查",
            //   icon: "el-icon-document",
            //   index: "3-1",
            //   children: [
            {
              index: "3-1-1",
              name: "专项检查",
              path: "/assess/Special_inspection",
            },

            {
              index: "3-1-3",
              name: "随机督察",
              path: "/assess/Random_inspector",
            },

            {
              index: "3-1-2",
              name: "检查模板",
              path: "/assess/Special_template",
            },
            {
              index: "3-1-5",
              name: "检查台账",
              path: "/assess/Special_standing",
            },

            {
              index: "3-1-8",
              name: "考核周期",
              path: "/assess/performance_appraisal",
            },

            {
              index: "3-1-10",
              name: "考核动态",
              path: "/assess/performance_dynamic",
            },

            {
              index: "3-1-11",
              name: "安全月评",
              path: "/assess/performance_review",
            },
            {
              index: "3-1-118",
              name: "月评台账",
              path: "/assess/review_standing",
            },

            // ],
            // },
          ],
        },
        {
          name: "审批管理",
          icon: "icon-contract2-fill",
          index: "4",
          children: [
            {
              index: "4-1",
              name: "审批模板",
              path: "/process/process_management",
            },
            {
              index: "4-2",
              name: "审批台账",
              path: "/process/process_standing",
            },
          ],
        },
        {
          name: "任务管理",
          icon: "icon-CombinedShape-",
          index: "5",
          children: [
            {
              index: "5-2",
              name: "任务台账",
              path: "/task/task_standing",
            },
          ],
        },
        {
          name: "系统管理",
          icon: "icon-multiplication-box",
          index: "2",
          children: [
            // {
            //   index: "2-3",
            //   name: "首页",
            //   path: "/system/personal_details",
            // },

            {
              index: "1-1",
              name: "风险源模板",
              path: "/security/risk_source",
            },
            {
              index: "1-18",
              name: "风险标签",
              path: "/security/risk_label",
            },
            {
              index: "1-12",
              name: "警示图片",
              path: "/security/risk_img",
            },
            {
              index: "5-1",
              name: "任务模板",
              path: "/task/task_template",
            },

            // {
            //   index: "5-3",
            //   name: "定时任务",
            //   path: "/task/task_timed",
            // },
            {
              index: "2-6",
              name: "组织架构",
              path: "/system/organizational_structure",
            },
            {
              index: "2-4",
              name: "角色管理",
              path: "/system/role_management",
            },

            {
              index: "2-9",
              name: "权限管理",
              path: "/system/authority_management",
            },
            {
              index: "2-7",
              name: "用户管理",
              path: "/system/user_control",
            },

            {
              index: "2-1",
              name: "学校管理",
              path: "/system/school_management",
            },
            {
              index: "1-16",
              name: "分类管理",
              path: "/security/risk_pointclassify",
            },
            // {
            //   index: "2-1",
            //   name: "班级管理",
            //   path: "/system/class_management",
            // },
            // {
            //   index: "2-2",
            //   name: "学生管理",
            //   path: "/system/student_management",
            // },
            {
              index: "2-11",
              name: "系统配置",
              path: "/system/system_layout",
            },
            // {
            //   index: "2-15",
            //   name: "风险配置",
            //   children: [

            //   ],
            // },
          ],
        },

        {
          name: "百年建党",
          icon: "icon-CombinedShape-",
          index: "20",
          children: [],
        },
        {
          name: "舆情管理",
          icon: "icon-CombinedShape-",
          index: "21",
          children: [],
        },
      ],
    };
  },
  methods: {
    handleMenuSelect(index, indexPath) { },
  },
};
</script>

<style lang="less" scoped>
/*隐藏文字*/
.el-menu--collapse .el-submenu__title span {
  display: none;
}

/*隐藏 > */
.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
}

.naviheigth {
  overflow-y: auto;
  flex: 1;
}

.naviheigth::-webkit-scrollbar {
  display: none;
  /* 隐藏滚动条 */
}

.navigation_left {
  margin-left: 23px !important;
}

.navigation-top {
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 64px;
  border-bottom: 1px solid rgba(0, 0, 0, .06);
  margin-left: 0 !important;

  img {
    width: 40px;
    height: 40px;
  }

  p {
    margin-left: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #4f7afd;
  }
}

.navigation-the {
  border-right: solid 1px #e6e6e6;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.el-menu {
  border: none;
}
</style>