<template>
  <div id="digital-flop">
    <div
      class="digital-flop-item"
      v-for="item in digitalFlopData"
      :key="item.title"
    >
      <div class="digital-flop-title">{{ item.title }}</div>
      <div class="digital-flop">
        <dv-digital-flop
          :config="item.number"
          style="width: 100px; height: 50px"
        />
        <div class="unit">{{ item.unit }}</div>
      </div>
    </div>

    <dv-decoration-10 />
  </div>
</template>

<script>
export default {
  name: "DigitalFlop",
  props: {
    digitalFlopData: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    console.log(this.digitalFlopData)
  },
  watch: {
    digitalFlopData: function (newValue, oldVal) {
      // console.log(123)
      console.log(newValue);
      this.digitalFlopData = newValue;
      this.digitalFlopData = { ...this.digitalFlopData };
    },
    deep: true,
  },
  data() {
    return {};
  },
  // methods: {
  //   createData() {
  //     this.digitalFlopData = [
  //       {
  //         title: "学生数量",
  //         number: {
  //           number: [10640],
  //           content: "{nt}",
  //           textAlign: "right",
  //           style: {
  //             fill: "#4d99fc",
  //             fontWeight: "bold",
  //           },
  //         },
  //         unit: "名",
  //       },
  //       {
  //         title: "教师数量",
  //         number: {
  //           number: [559],
  //           content: "{nt}",
  //           textAlign: "right",
  //           style: {
  //             fill: "#f46827",
  //             fontWeight: "bold",
  //           },
  //         },
  //         unit: "名",
  //       },
  //       {
  //         title: "保安数量",
  //         number: {
  //           number: [26],
  //           content: "{nt}",
  //           textAlign: "right",
  //           style: {
  //             fill: "#40faee",
  //             fontWeight: "bold",
  //           },
  //         },
  //         unit: "名",
  //       },
  //       {
  //         title: "风险点数量",
  //         number: {
  //           number: [336],
  //           content: "{nt}",
  //           textAlign: "right",
  //           style: {
  //             fill: "#4d99fc",
  //             fontWeight: "bold",
  //           },
  //         },
  //         unit: "个",
  //       },
  //       {
  //         title: "安保设备",
  //         number: {
  //           number: [230],
  //           content: "{nt}",
  //           textAlign: "right",
  //           style: {
  //             fill: "#f46827",
  //             fontWeight: "bold",
  //           },
  //         },
  //         unit: "个",
  //       },
  //       {
  //         title: "消防设施",
  //         number: {
  //           number: [248],
  //           content: "{nt}",
  //           textAlign: "right",
  //           style: {
  //             fill: "#40faee",
  //             fontWeight: "bold",
  //           },
  //         },
  //         unit: "个",
  //       },
  //       {
  //         title: "安防监控",
  //         number: {
  //           number: [562],
  //           content: "{nt}",
  //           textAlign: "right",
  //           style: {
  //             fill: "#4d99fc",
  //             fontWeight: "bold",
  //           },
  //         },
  //         unit: "个",
  //       },
  //       {
  //         title: "人脸闸机",
  //         number: {
  //           number: [9],
  //           content: "{nt}",
  //           textAlign: "right",
  //           style: {
  //             fill: "#f46827",
  //             fontWeight: "bold",
  //           },
  //         },
  //         unit: "个",
  //       },
  //       {
  //         title: "车辆道闸",
  //         number: {
  //           number: [2],
  //           content: "{nt}",
  //           textAlign: "right",
  //           style: {
  //             fill: "#40faee",
  //             fontWeight: "bold",
  //           },
  //         },
  //         unit: "个",
  //       },
  //     ];
  //   },
  // },
};
</script>

<style lang="less" scoped>
#digital-flop {
  position: relative;
  height: 15%;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(6, 30, 93, 0.5);

  .dv-decoration-10 {
    position: absolute;
    width: 95%;
    left: 2.5%;
    height: 5px;
    bottom: 0px;
  }

  .digital-flop-item {
    width: 11%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 3px solid rgb(6, 30, 93);
    border-right: 3px solid rgb(6, 30, 93);
  }

  .digital-flop-title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .digital-flop {
    display: flex;
  }

  .unit {
    margin-left: 10px;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    padding-bottom: 13px;
  }
}
</style>
