import router from "@/router/index";
import { Loading, Message } from "element-ui";
import axios from "axios";
axios.defaults.baseURL = "/";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
// 请求前配置
let loadingInstance;
axios.interceptors.request.use((config) => {
  if (
    config.url == "/index/index/verify" ||
    config.url == "/api/dangerous/exportword" ||
    config.url == "/api/dangerous/exportExl"
  )
    config.responseType = "blob";
  let token = sessionStorage.getItem("token") || localStorage.getItem("token");
  config.headers["authorization"] = `Bearer ${token}`;

  loadingInstance = Loading.service({ fullscreen: true });
  return config;
});
export default async (url, data = {}, type = "GET", config) => {
  return new Promise(async (resolve, reject) => {
    if (type === "POST") {
      await axios.post(url, data, config).then(
        (response) => {
          successMsg(response.data);
          resolve(response.data);
        },
        (err) => {
          reject(err);
        }
      );
    }
    if (type === "GET") {
      await axios
        .get(url, {
          params: data,
        })
        .then((response) => {
          successMsg(response.data);
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};
// 接口返回 拦截器
axios.interceptors.response.use(
  (response) => {
    if (loadingInstance) {
      loadingInstance.close();
    }
    return response;
  },
  (error) => {
    if (loadingInstance) {
      loadingInstance.close();
    }
    errorMsg(error);
    return Promise.reject(error);
  }
);
/**
 * 服务器连接失败信息提示
 * @param {*} status
 */
async function errorMsg(error) {
  const status =
    error.response && error.response.status ? error.response.status : error;
  switch (status) {
    case 500:
      Message.error({
        showClose: true,
        message: "服务器报错",
        type: "error",
      });
      break;
    case 404:
      Message.error({
        showClose: true,
        message: "未找到接口",
        type: "error",
      });
      break;
    default:
      Message.error({
        showClose: true,
        message: "网络错误",
        type: "error",
      });
  }
}

// 请求成功 逻辑错误处理
function successMsg(data) {
  if (data.err_code === 500) {
    Message.error({
      showClose: true,
      message: data.msg,
      type: "error",
    });

    router.push("/login");
  }
  if (data.code === -100) {
    Message.error({
      showClose: true,
      message: data.msg,
      type: "error",
    });
  }

  if (data.code === -1) {
    Message.error({
      showClose: true,
      message: data.msg,
      type: "error",
    });
  }
  if (data.code === -1000) {
    Message.error({
      showClose: true,
      message: data.msg,
      type: "error",
    });
  }
}
