import { Loading } from 'element-ui';
let loadingInstance
export default {
    //验证手机号
    verify_phone(value) {
        let isphone = /^1(3|4|5|6|7|8|9)\d{9}$/;
        return isphone.test(value)
    },
    //验证巡检周期，只能输入非0正整数
    verify_sky(value) {
        let is_sky = /^[1-9]\d*$/;
        return is_sky.test(value)
    },
    //上传参数token
    config() {
        let token = sessionStorage.getItem("token");
        return { authorization: `Bearer ${token}` };
    },
    //星号替换名称
    replacename(name) {
        let newStr;
        if (!name) {
            return name
        }
        if (name.length === 2) {
            newStr = name.substr(0, 1) + '*';
        } else if (name.length > 2) {
            let char = '';
            for (let i = 0, len = name.length - 2; i < len; i++) {
                char += '*';
            }
            newStr = name.substr(0, 1) + char + name.substr(-1, 1);
        } else {
            newStr = name;
        }
        return newStr;
    },
    //计算百分比
    way_percentage(num, total) {
        if (num == 0 || total == 0) {
            return 0;
        }
        let percentage = Math.round(num / total * 10000) / 100.00

        return percentage
    },
    //根据百分比决定颜色
    way_colour(num, total) {
        let percentage
        if (num == 0 || total == 0) {
            percentage = 0
        } else {
            percentage = Math.round(num / total * 10000) / 100.00

        }
        if (percentage == 0) {
            return "#EBEEF5"
        } else if (percentage < 50) {
            return "red"
        } else if (percentage < 70) {
            return "#E6A23C"
        } else if (percentage < 100) {
            return "#20A0FF"
        } else {
            return "#13CE66"
        }
    },

    //打开全局遮罩
    shade_open() {
        loadingInstance = Loading.service({ fullscreen: true, text: "上传中，请稍等" });
    },
    //关闭全局遮罩
    close_open() {
        loadingInstance.close();
    },

    //将字符串图片分割成数组展示
    img_split(imgList) {
        if (imgList) {
            return imgList.split(",")
        } else {
            return []
        }
    },
    //判断结束时间是否大于开始时间
    Time_todetermine(date, begin_Date) {
        return date.getTime() <
            new Date(begin_Date).getTime() - 86400000
    },
    //获取当前日期推迟的日期
    getNextDate(day) {
        let dd = new Date();
        dd.setDate(dd.getDate() + day);
        let y = dd.getFullYear();
        let m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
        let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
        return y + "-" + m + "-" + d;
    },
    //清楚登录信息
    Clear_information(){
        sessionStorage.removeItem("menu");
        sessionStorage.removeItem("base");
        sessionStorage.removeItem("userList");
        sessionStorage.removeItem("personage");
        sessionStorage.removeItem("token");
        localStorage.removeItem("token");
    }
};
