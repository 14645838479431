import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import control_standing from "../views/security/control_standing.vue";
import datav from "../views/workbench/visual_page.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../login.vue"),
  },
  //登录页
  {
    path: "/login",
    name: "login",
    component: () => import("../login.vue"),
  },

  {
    path: "/risk_card",
    name: "risk_card",
    meta: {
      title: "风险点告知卡",
    },
    component: () => import("../views/security/risk_card.vue"),
  },
  //可视化页面
  {
    path: "/datav",
    name: "datav",
    meta: {
      title: "风险点告知卡",
    },
    component: () => import("../views/Datav/datav.vue"),
  },

  //找回密码
  {
    path: "/retrievepass",
    name: "retrievepass",
    meta: {
      title: "风险点告知卡",
    },
    component: () => import("../retrievepass.vue"),
  },

  //工作台
  {
    path: "/workbench",
    name: "workbench",
    meta: {
      name: "工作台",
    },
    component: Home,
    children: [
      //工作操作
      {
        path: "work_page",
        name: "work_page",
        meta: {
          crumbs: ["工作台", "首页"],
        },
        component: () => import("../views/workbench/work_page.vue"),
      },
      // 线上110
      {
        path: "web110",
        name: "web110",
        meta: {
          crumbs: ["线上110"],
        },
        component: () => import("../views/workbench/web110.vue"),
      },
      //可视化页面
      // {
      //   path: "visual_page",
      //   name: "visual_page",
      //   meta: {
      //     crumbs: ["工作台", "可视化页面"]
      //   },
      //   component: () => import("../views/workbench/visual_page.vue"),
      // },

      {
        path: "safety_large",
        name: "safety_large",
        meta: {
          crumbs: ["工作台", "安全态势"],

          type: 1,
        },
        component: datav,
      },
      {
        path: "data_large",
        name: "data_large",
        meta: {
          crumbs: ["工作台", "数据态势"],
          type: 2,
        },
        component: datav,
      },
    ],
  },

  {
    path: "/security",
    name: "security",
    redirect: "/security/security",
    meta: {
      title: "安全管控",
    },
    component: Home,
    children: [
      {
        path: "risk_source",
        name: "risk_source",
        meta: {
          title: "风险源",
          crumbs: ["系统管理", "风险源模板"],
        },
        component: () => import("../views/security/risk_source.vue"),
      },
      {
        path: "risk_sourceredact",
        name: "risk_sourceredact",
        meta: {
          title: "风险源",
          crumbs: ["系统管理", "风险源模板管理"],
        },
        component: () => import("../views/security/risk_sourceredact.vue"),
      },
      {
        path: "risk_point",
        name: "risk_point",
        meta: {
          title: "风险点",
          crumbs: ["风险点管控", "风险点"],
        },
        component: () => import("../views/security/risk_point.vue"),
      },
      {
        path: "risk_particulars",
        name: "risk_particulars",
        meta: {
          title: "风险点详情",
          crumbs: ["风险点管控", "风险点详情"],
        },
        component: () => import("../views/security/risk_particulars.vue"),
      },
      {
        path: "risk_pointredact",
        name: "risk_pointredact",
        meta: {
          title: "风险点",
          crumbs: ["风险点管控", "风险点操作"],
        },
        component: () => import("../views/security/risk_pointredact.vue"),
      },
      {
        path: "irregular",
        name: "irregular",
        meta: {
          title: "违规物品",
          crumbs: ["违规物品", "违规物品操作"],
        },
        component: () => import("../views/security/irregular.vue"),
      },
      {
        path: "irregularSaveinfo",
        name: "irregularSaveinfo",
        meta: {
          title: "违规物品",
          crumbs: ["违规物品", "违规物品操作"],
        },
        component: () => import("../views/security/irregular_saveinfo.vue"),
      },
      {
        path: "Car",
        name: "Car",
        meta: {
          crumbs: ["车辆管理"],
        },
        component: () => import("../views/security/Car.vue"),
      },
      {
        path: "car_code",
        name: "car_code",
        meta: {
          crumbs: ["行车码管理"],
        },
        component: () => import("../views/security/car_code.vue"),
      },
      {
        path: "risk_pointclassify",
        name: "risk_pointclassify",
        meta: {
          title: "风险点",
          crumbs: ["系统管理", "分类管理"],
        },
        component: () => import("../views/security/risk_pointclassify.vue"),
      },
      //风险点管控台账
      {
        path: "control_standing",
        name: "control_standing",
        meta: {
          title: "管控台账",
          url_type: "dangerous",
          crumbs: ["风险点管控", "管控台账"],
        },
        component: control_standing,
      },
      //重点人群管控台账
      {
        path: "emphasie_standing",
        name: "emphasie_standing",
        meta: {
          title: "管控台账",
          url_type: "personnel",
          crumbs: ["重点人群", "管控台账"],
        },
        component: control_standing,
      },
      //安全活动管控台账
      {
        path: "thought_standing",
        name: "thought_standing",
        meta: {
          title: "管控台账",
          url_type: "eduction",
          crumbs: ["安全活动", "管控台账"],
        },
        component: control_standing,
      },

      //制度管理管控台账
      {
        path: "system_standing",
        name: "system_standing",
        meta: {
          title: "管控台账",
          url_type: "regulation",
          crumbs: ["制度管理", "制度详情"],
        },
        component: control_standing,
      },

      //事件管理管控台账
      {
        path: "case_standing",
        name: "case_standing",
        meta: {
          title: "管控台账",
          url_type: "cases",
          crumbs: ["事件管理", "管控台账"],
        },
        component: control_standing,
      },

      //活动报备管控台账
      {
        path: "activity_standing",
        name: "activity_standing",
        meta: {
          title: "管控台账",
          url_type: "activity",
          crumbs: ["活动报备", "管控台账"],
        },
        component: control_standing,
      },

      //隐患管理管控台账
      {
        path: "hidden_standing",
        name: "hidden_standing",
        meta: {
          title: "管控台账",
          url_type: "hdanger",
          crumbs: ["隐患管理", "管控台账"],
        },
        component: control_standing,
      },
      //风险源标签
      {
        path: "risk_label",
        name: "risk_label",
        meta: {
          crumbs: ["系统管理", "风险标签"],
        },
        component: () => import("../views/security/risk_label.vue"),
      },
      //警示图片
      {
        path: "risk_img",
        name: "risk_img",
        meta: {
          crumbs: ["系统管理", "警示图片"],
        },
        component: () => import("../views/security/risk_img.vue"),
      },

      //重点人群
      {
        path: "focus_groups",
        name: "focus_groups",
        meta: {
          crumbs: ["重点人群", "重点人群"],
        },
        component: () => import("../views/security/focus_groups.vue"),
      },
      //重点人群详情
      {
        path: "focus_particulars",
        name: "focus_particulars",
        meta: {
          crumbs: ["重点人群", "重点人群详情"],
        },
        component: () => import("../views/security/focus_particulars.vue"),
      },
      //安全活动
      {
        path: "thought_education",
        name: "thought_education",
        meta: {
          crumbs: ["安全活动", "安全活动"],
        },
        component: () => import("../views/security/thought_education.vue"),
      },

      //安全活动修改
      {
        path: "thought_modification",
        name: "thought_modification",
        meta: {
          crumbs: ["安全宣教", "添加操作"],
        },
        component: () => import("../views/security/thought_modification.vue"),
      },

      //安全活动详情
      {
        path: "thought_particulars",
        name: "thought_particulars",
        meta: {
          crumbs: ["安全活动", "安全活动详情"],
        },
        component: () => import("../views/security/thought_particulars.vue"),
      },

      //制度管理
      {
        path: "system_administration",
        name: "system_administration",
        meta: {
          crumbs: ["制度管理", "制度管理列表"],
        },
        component: () => import("../views/security/system_administration.vue"),
      },
      //制度管理修改
      {
        path: "system_modification",
        name: "system_modification",
        meta: {
          crumbs: ["制度管理", "制度管理操作"],
        },
        component: () => import("../views/security/system_modification.vue"),
      },
      //制度管理详情
      {
        path: "system_particulars",
        name: "system_particulars",
        meta: {
          crumbs: ["制度管理", "制度管理详情"],
        },
        component: () => import("../views/security/system_particulars.vue"),
      },

      //事件管理
      {
        path: "case_management",
        name: "case_management",
        meta: {
          crumbs: ["事件管理", "事件管理列表"],
        },
        component: () => import("../views/security/case_management.vue"),
      },
      //事件管理修改
      {
        path: "case_redact",
        name: "case_redact",
        meta: {
          crumbs: ["事件管理", "事件管理操作"],
        },
        component: () => import("../views/security/case_redact.vue"),
      },
      //事件管理详情
      {
        path: "case_particulars",
        name: "case_particulars",
        component: () => import("../views/security/case_particulars.vue"),
      },
      //活动报备列表
      {
        path: "Activities_backup",
        name: "Activities_backup",
        meta: {
          crumbs: ["活动报备", "活动报备列表"],
        },
        component: () => import("../views/security/Activities_backup.vue"),
      },
      //活动报备详情
      {
        path: "Activities_particulars",
        name: "Activities_particulars",
        meta: {
          crumbs: ["活动报备", "活动报备详情"],
        },
        component: () => import("../views/security/Activities_particulars.vue"),
      },
      //隐患管理
      {
        path: "Risk_management",
        name: "Risk_management",
        meta: {
          crumbs: ["隐患管理", "隐患管理列表"],
        },
        component: () => import("../views/security/Risk_management.vue"),
      },
      //隐患管理详情
      {
        path: "Risk_manparticulars",
        name: "Risk_manparticulars",
        meta: {
          crumbs: ["隐患管理", "隐患管理详情"],
        },
        component: () => import("../views/security/Risk_manparticulars.vue"),
      },
      //网格化管理管理
      {
        path: "Soldier_brother",
        name: "Soldier_brother",
        meta: {
          crumbs: ["网格化管理", "网格化管理管理"],
        },
        component: () => import("../views/security/Soldier_brother.vue"),
      },
      //网格化管理管理详情
      {
        path: "Soldier_particulars",
        name: "Soldier_particulars",
        meta: {
          crumbs: ["网格化管理", "网格化管理列表"],
        },
        component: () => import("../views/security/Soldier_particulars.vue"),
      },
      //网格化管理管控台账
      {
        path: "Soldier_brothersstanding",
        name: "Soldier_brothersstanding",
        meta: {
          title: "管控台账",
          url_type: "army",
          crumbs: ["网格化管理", "管控台账"],
        },
        component: control_standing,
      },

      //排班管理
      {
        path: "duty_arrange",
        name: "duty_arrange",
        meta: {
          crumbs: ["值班管理", "排班管理"],
        },
        component: () => import("../views/security/duty_arrange.vue"),
      },
      {
        path: "duty_particulars",
        name: "duty_particulars",
        meta: {
          title: "风险点详情",
          crumbs: ["风险点管控", "风险点详情"],
        },
        component: () => import("../views/security/duty_particulars.vue"),
      },
      //排班管理
      {
        path: "duty_arrangeredact",
        name: "duty_arrangeredact",
        meta: {
          crumbs: ["值班管理", "排班管理操作"],
        },
        component: () => import("../views/security/duty_arrangeredact.vue"),
      },

      //班次管理
      {
        path: "duty_management",
        name: "duty_management",
        meta: {
          crumbs: ["值班管理", "班次管理"],
        },
        component: () => import("../views/security/duty_management.vue"),
      },
      {
        path: "duty_redact",
        name: "duty_redact",
        meta: {
          crumbs: ["值班管理", "班次管理操作"],
        },
        component: () => import("../views/security/duty_redact.vue"),
      },
      //值班日志
      {
        path: "duty_logbook",
        name: "duty_logbook",
        meta: {
          crumbs: ["值班管理", "值班日志"],
        },
        component: () => import("../views/security/duty_logbook.vue"),
      },

      //排班日志

      {
        path: "Scheduling_thelog",
        name: "Scheduling_thelog",
        component: () => import("../views/security/Scheduling_thelog.vue"),
      },
    ],
  },
  {
    path: "/system",
    name: "system",
    meta: {
      name: "系统管理",
    },
    component: Home,
    children: [
      //首页
      {
        path: "personal_details",
        name: "personal_details",
        component: () => import("../views/system/personal_details.vue"),
      },
      //权限管理
      {
        path: "authority_management",
        name: "authority_management",
        meta: {
          crumbs: ["系统管理", "权限管理"],
        },
        component: () => import("../views/system/authority_management.vue"),
      },

      //用户管理
      {
        path: "user_control",
        name: "user_control",
        meta: {
          crumbs: ["系统管理", "用户管理"],
        },
        component: () => import("../views/system/user_control.vue"),
      },
      //学生管理
      {
        path: "student_management",
        name: "student_management",
        component: () => import("../views/system/student_management.vue"),
      },
      //学生管理修改
      {
        path: "student_redacy",
        name: "student_redacy",
        component: () => import("../views/system/student_redacy.vue"),
      },
      //班级管理
      {
        path: "class_management",
        name: "class_management",
        component: () => import("../views/system/class_management.vue"),
      },
      //角色管理
      {
        path: "role_management",
        name: "role_management",
        meta: {
          crumbs: ["系统管理", "角色管理"],
        },
        component: () => import("../views/system/role_management.vue"),
      },
      //组织架构
      {
        path: "organizational_structure",
        name: "organizational_structure",
        meta: {
          crumbs: ["系统管理", "组织架构"],
        },
        component: () => import("../views/system/organizational_structure.vue"),
      },
      //系统配置
      {
        path: "system_layout",
        name: "system_layout",
        meta: {
          crumbs: ["系统管理", "系统配置"],
        },
        component: () => import("../views/system/system_layout.vue"),
      },
      //学校管理
      {
        path: "school_management",
        name: "school_management",
        meta: {
          crumbs: ["系统管理", "学校管理"],
        },
        component: () => import("../views/system/school_management.vue"),
      },
    ],
  },
  {
    path: "/service",
    name: "service",
    meta: {
      name: "服务不缺位",
    },
    component: Home,
    children: [
      {
        path: "household_manage",
        name: "household_manage",
        meta: {
          crumbs: ["户政管理", "户政记录列表"],
        },
        component: () => import("../views/service/household_manage.vue"),
      },
      {
        path: "video_record",
        name: "video_record",
        meta: {
          crumbs: ["视频查阅", "视频查阅列表"],
        },
        component: () => import("../views/service/video_record.vue"),
      },
      {
        path: "Security_requirement",
        name: "Security_requirement",
        meta: {
          crumbs: ["安保需求", "安保需求列表"],
        },
        component: () => import("../views/service/Security_requirement.vue"),
      },
      {
        path: "emigration",
        name: "emigration",
        meta: {
          crumbs: ["户籍迁出", "户籍迁出列表"],
        },
        component: () => import("../views/service/emigration.vue"),
      },
    ],
  },
  {
    path: "/ceaseless",
    name: "ceaseless",
    meta: {
      name: "安保不间断",
    },
    component: Home,
    children: [
      {
        path: "goodness",
        name: "goodness",
        meta: {
          crumbs: ["每日一善", "每日一善列表"],
        },
        component: () => import("../views/ceaseless/goodness.vue"),
      },
      {
        path: "Conference_ledger",
        name: "Conference_ledger",
        meta: {
          crumbs: ["会议台账", "会议台账列表"],
        },
        component: () => import("../views/ceaseless/Conference_ledger.vue"),
      },
      {
        path: "Training_ledger",
        name: "Training_ledger",
        meta: {
          crumbs: ["培训台账", "培训台账列表"],
        },
        component: () => import("../views/ceaseless/Training_ledger.vue"),
      },
      {
        path: "School_guard",
        name: "School_guard",
        meta: {
          crumbs: ["护校铁军", "护校铁军列表"],
        },
        component: () => import("../views/ceaseless/School_guard.vue"),
      },
      {
        path: "Overtime_record",
        name: "Overtime_record",
        meta: {
          crumbs: ["加班记录", "加班记录列表"],
        },
        component: () => import("../views/ceaseless/Overtime_record.vue"),
      },
      {
        path: "Routine_patrol",
        name: "Routine_patrol",
        meta: {
          crumbs: ["日常巡逻", "日常巡逻列表"],
        },
        component: () => import("../views/ceaseless/Routine_patrol.vue"),
      },
      {
        path: "Afterhours_log",
        name: "Afterhours_log",
        meta: {
          crumbs: ["下班日志", "下班日志列表"],
        },
        component: () => import("../views/ceaseless/Afterhours_log.vue"),
      },
      {
        path: "commuting",
        name: "commuting",
        meta: {
          crumbs: ["上下班交接", "上下班交接列表"],
        },
        component: () => import("../views/ceaseless/commuting.vue"),
      },
    ],
  },

  {
    path: "/antinomy",
    name: "antinomy",
    meta: {
      name: "矛盾不上交",
    },
    component: Home,
    children: [
      {
        path: "hundred_responses",
        name: "hundred_responses",
        meta: {
          crumbs: ["一呼百应", "一呼百应列表"],
        },
        component: () => import("../views/antinomy/hundred_responses.vue"),
      },
    ],
  },
  {
    path: "/process",
    name: "process",
    meta: {
      name: "审批管理",
    },
    component: Home,
    children: [
      //审批模板
      {
        path: "process_management",
        name: "process_management",
        meta: {
          crumbs: ["审批管理", "审批模板表格"],
        },
        component: () => import("../views/process/process_management.vue"),
      },
      //审批台账
      {
        path: "process_standing",
        name: "process_standing",
        meta: {
          crumbs: ["审批管理", "审批台账"],
        },
        component: () => import("../views/process/process_standing.vue"),
      },
    ],
  },
  {
    path: "/task",
    name: "task",
    meta: {
      name: "任务管理",
    },
    component: Home,
    children: [
      //任务管理
      {
        path: "task_template",
        name: "task_template",
        meta: {
          crumbs: ["系统管理", "任务模板"],
        },
        component: () => import("../views/task/task_template"),
      },
      //任务管理
      {
        path: "task_timed",
        name: "task_timed",
        component: () => import("../views/task/task_timed"),
      },
      //任务管理
      {
        path: "task_standing",
        name: "task_standing",
        meta: {
          crumbs: ["任务管理", "任务台账"],
        },
        component: () => import("../views/task/task_standing"),
      },
    ],
  },

  {
    path: "/assess",
    name: "assess",
    meta: {
      name: "考核管理",
    },
    component: Home,

    children: [
      //附加分值
      {
        path: "addition_score",
        name: "addition_score",
        meta: {
          crumbs: ["考核管理", "附加分值"],
        },

        component: () => import("../views/assess/addition_score.vue"),
      },

      //随机督察
      {
        path: "Random_inspector",
        name: "Random_inspector",
        meta: {
          url_type: "spection",
          crumbs: ["考核管理", "随机督察"],
        },

        component: () => import("../views/assess/Random_inspector.vue"),
      },
      //专项台账

      {
        path: "Special_standing",
        name: "Special_standing",
        meta: {
          crumbs: ["考核管理", "检查台账"],
        },

        component: () => import("../views/assess/Special_standing.vue"),
      },
      //专项台账详情

      {
        path: "Special_standingparticulars",
        name: "Special_standingparticulars",
        component: () =>
          import("../views/assess/Special_standingparticulars.vue"),
      },
      {
        path: "Inspection_particulars",
        name: "Inspection_particulars",
        component: () => import("../views/assess/inspection_particulars.vue"),
      },
      {
        path: "Spection_particulars",
        name: "Spection_particulars",
        component: () => import("../views/assess/spection_particulars.vue"),
      },
      //专项检查z
      {
        path: "Special_inspection",
        name: "Special_inspection",
        meta: {
          crumbs: ["考核管理", "专项检查"],
        },
        component: () => import("../views/assess/Special_inspection.vue"),
      },

      //专项检查 列表
      {
        path: "Inspect",
        name: "Inspect",
        meta: {
          crumbs: ["考核管理", "专项检查"],
        },
        component: () => import("../views/assess/Inspect.vue"),
      },
      {
        path: "Special_inspectionredel",
        name: "Special_inspectionredel",
        meta: {
          crumbs: ["考核管理", "专项检查详情"],
        },
        component: () => import("../views/assess/Special_inspectionredel.vue"),
      },

      //专项检查模板
      {
        path: "Special_template",
        name: "Special_template",
        meta: {
          crumbs: ["考核管理", "检查模板"],
        },

        component: () => import("../views/assess/Special_template.vue"),
      },
      //专项检查模板修改
      {
        path: "Special_templatealter",
        name: "Special_templatealter",
        component: () => import("../views/assess/Special_templatealter.vue"),
      },

      //考核周期
      {
        path: "performance_appraisal",
        name: "performance_appraisal",
        meta: {
          crumbs: ["考核管理", "考核周期"],
        },

        component: () => import("../views/assess/performance_appraisal.vue"),
      },
      //考核动态
      {
        path: "performance_dynamic",
        name: "performance_dynamic",
        meta: {
          crumbs: ["考核管理", "考核动态"],
        },
        component: () => import("../views/assess/performance_dynamic.vue"),
      },

      //考核月评
      {
        path: "performance_review",
        name: "performance_review",
        meta: {
          crumbs: ["考核管理", "考核月评"],
        },
        component: () => import("../views/assess/performance_review.vue"),
      },

      //考核评论修改
      {
        path: "performance_reviewredact",
        name: "performance_reviewredact",
        meta: {
          crumbs: ["考核管理", "考核月评详情"],
        },
        component: () => import("../views/assess/performance_reviewredact.vue"),
      },

      //月评台账
      {
        path: "review_standing",
        name: "review_standing",
        meta: {
          crumbs: ["考核管理", "月评台账"],
        },
        component: () => import("../views/assess/review_standing.vue"),
      },

      //月评台账详情

      {
        path: "review_standingparticulars",
        name: "review_standingparticulars",
        meta: {
          crumbs: ["考核管理", "月评台账详情"],
        },
        component: () =>
          import("../views/assess/review_standingparticulars.vue"),
      },
    ],

    // children: [
    //   //专项检查
    //   {
    //     path: "examine",
    //     name: "examine",
    //     component: {
    //       render(c) {
    //         return c("router-view");
    //       },
    //     },
    //     children: [
    //       //专项检查
    //       {
    //         path: "Special_inspection",
    //         name: "Special_inspection",
    //         component: () =>
    //           import("../views/assess/examine/Special_inspection.vue"),
    //       },
    //       //专项检查模板
    //       {
    //         path: "Special_template",
    //         name: "Special_template",
    //         component: () =>
    //           import("../views/assess/examine/Special_template.vue"),
    //       },
    //       //专项检查模板修改
    //       {
    //         path: "Special_templatealter",
    //         name: "Special_templatealter",
    //         component: () =>
    //           import("../views/assess/examine/Special_templatealter.vue"),
    //       },
    //     ],
    //   },
    // ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
