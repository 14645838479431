var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"from_outer"},[_c('div',[_c('el-form',{ref:"formName",attrs:{"model":_vm.formLabelAlign,"label-width":"150px"}},_vm._l((_vm.formData),function(item,index){return _c('div',{key:index,staticClass:"div-sty"},[(item.type == 'text' || item.type == 'textarea')?_c('el-form-item',{attrs:{"label":item.label,"prop":item.model,"rules":{
            required: item.options.isrules,
            message: '请选择',
            trigger: ['change'],
          }}},[_c('el-input',{staticClass:"input-sty",attrs:{"disabled":_vm.isdisabled,"type":item.type,"placeholder":item.placeholder},model:{value:(_vm.formLabelAlign[item.model]),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, item.model, $$v)},expression:"formLabelAlign[item.model]"}})],1):_vm._e(),(item.type == 'radio')?_c('el-form-item',{attrs:{"label":item.label,"prop":item.model,"rules":{
            required: item.options.isrules,
            message: '请选择',
            trigger: ['change'],
          }}},[_c('el-radio-group',{model:{value:(_vm.formLabelAlign[item.model]),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, item.model, $$v)},expression:"formLabelAlign[item.model]"}},_vm._l((item.options.list),function(el,ins){return _c('el-radio',{key:ins,attrs:{"disabled":_vm.isdisabled,"label":el.value}},[_vm._v(_vm._s(el.name))])}),1)],1):_vm._e(),(item.type == 'checkbox')?_c('el-form-item',{attrs:{"label":item.label,"prop":item.model,"rules":{
            required: item.options.isrules,
            message: '请选择',
            trigger: ['change'],
          }}},[(item.select_List)?_c('el-checkbox-group',{attrs:{"disabled":_vm.isdisabled}},_vm._l((item.select_List),function(el,index){return _c('el-checkbox',{key:index},[_vm._v(_vm._s(el))])}),1):_vm._e()],1):_vm._e(),(item.type == 'upload_img')?_c('el-form-item',{attrs:{"label":item.label,"prop":item.model,"rules":{
            required: item.options.isrules,
            message: '请选择',
            trigger: ['change'],
          }}},[_c('div',{staticClass:"upload"},[_vm._l((_vm.formLabelAlign[item.model]),function(el,index){return _c('div',{key:index,staticClass:"upload-sty"},[_c('el-image',{staticClass:"imgs",attrs:{"src":el,"closable":""}}),_c('i',{staticClass:"el-icon-circle-close",on:{"click":function($event){return _vm.on_deleteimg(index, item.model)}}})],1)}),_c('el-upload',{attrs:{"list-type":"picture-card","action":_vm.$store.state.uploadImage,"headers":_vm.$tools.config(),"data":{
                path_name: 'danger_icon',
              },"show-file-list":false,"on-success":(res) => _vm.handleSuccessimg(res, item.model),"before-upload":_vm.beforeAvatarUploadimg}},[_c('i',{staticClass:"el-icon-plus",attrs:{"slot":"default"},slot:"default"})])],2)]):_vm._e(),(item.type == 'upload_video')?_c('el-form-item',{attrs:{"label":item.label,"prop":item.model,"rules":{
            required: item.options.isrules,
            message: '请选择',
            trigger: ['change'],
          }}},[_c('div',{staticClass:"upload"},[(_vm.formLabelAlign[item.model])?_c('video',{staticClass:"upload-videosty",attrs:{"controls":"controls","src":_vm.formLabelAlign[item.model]}}):_c('el-upload',{attrs:{"list-type":"picture-card","action":_vm.$store.state.uploadVideo,"headers":_vm.$tools.config(),"data":{
                path_name: 'danger_icon',
              },"show-file-list":false,"on-success":(res) => _vm.beforeAvatarUpload_video(res, item.model),"before-upload":_vm.handleAvatarSuccess_video}},[_c('i',{staticClass:"el-icon-plus",attrs:{"slot":"default"},slot:"default"})])],1)]):_vm._e(),(item.type == 'upload_file')?_c('el-form-item',{attrs:{"label":item.label,"prop":item.model,"rules":{
            required: item.options.isrules,
            message: '请选择',
            trigger: ['change'],
          }}},[_c('div',[_c('el-upload',{ref:"upload",refInFor:true,staticClass:"upload-demo",attrs:{"action":_vm.$store.state.uploadFile,"headers":_vm.$tools.config(),"data":{
                path_name: 'danger_icon',
              },"show-file-list":false,"on-success":(res) => _vm.beforeAvatarUpload_file(res, item.model),"before-upload":_vm.handleAvatarSuccess_file,"list-type":"picture"}},[_c('div',_vm._l((_vm.formLabelAlign[item.model]),function(el,index){return _c('el-tag',{key:index,attrs:{"closable":""},on:{"close":function($event){return _vm.on_deleteFile(index, item.model)}}},[_vm._v("附件")])}),1),_c('el-button',{attrs:{"slot":"trigger","size":"small","type":"primary"},slot:"trigger"},[_vm._v("选择文件")])],1)],1)]):_vm._e(),(item.type == 'select')?_c('el-form-item',{attrs:{"label":item.name,"prop":item.value,"rules":{
            required: item.isrules,
            message: '请选择',
            trigger: ['change'],
          }}},[_c('el-select',{attrs:{"disabled":_vm.isdisabled,"placeholder":item.placeholder},model:{value:(_vm.formLabelAlign[item.value]),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, item.value, $$v)},expression:"formLabelAlign[item.value]"}},_vm._l((item.select_List),function(el,ins){return _c('el-option',{key:ins,attrs:{"label":el,"value":ins}})}),1)],1):_vm._e(),(item.type == 'switch')?_c('el-form-item',{attrs:{"label":item.label,"prop":item.model,"rules":{
            required: item.options.isrules,
            message: '请选择',
            trigger: ['change'],
          }}},[_c('el-switch',{attrs:{"disabled":_vm.isdisabled,"active-value":"1","inactive-value":"2","active-text":item.active_text,"inactive-text":item.inactive_text},model:{value:(_vm.formLabelAlign[item.model]),callback:function ($$v) {_vm.$set(_vm.formLabelAlign, item.model, $$v)},expression:"formLabelAlign[item.model]"}})],1):_vm._e()],1)}),0),(!_vm.isdisabled)?_c('div',{staticStyle:{"text-align":"center","margin":"20px 0"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.on_preserve('formName')}}},[_vm._v("保存")]),_c('el-button',{on:{"click":_vm.route_go}},[_vm._v("返回")])],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }