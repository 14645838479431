<template>
  <div id="data-view" :key="key">
    <dv-full-screen-container>
      <top-header />

      <div class="main-content">
        <digital-flop :digitalFlopData="digitalFlopData" :key="digitalindex" />

        <!-- <div id="digital-flop">
          <div
            class="digital-flop-item"
            v-for="item in digitalFlopData"
            :key="item.title"
          >
            <div class="digital-flop-title">{{ item.title }}</div>
            <div class="digital-flop">
              <dv-digital-flop
                :config="item.number"
                style="width: 100px; height: 50px"
              />
              <div class="unit">{{ item.unit }}</div>
            </div>
          </div>

          <dv-decoration-10 />
        </div> -->

        <div class="block-left-right-content">
          <div class="block-top-bottom-content">
            <div class="block-top-content">
              <div id="scroll-board">
                <div class="ranking-board-title">部门风险点数量列表</div>
                <dv-scroll-board :config="department_config" />
              </div>

              <scroll-riskpoint />
              <rose-chart :key="key" :personnlList="personnlList" />
            </div>
            <BottomCharts :dangerCateColor="dangerCateColor" />
          </div>

          <ranking-board :secDepartments="secDepartments" />
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import topHeader from "./topHeader";
import digitalFlop from "./digitalFlop";
import rankingBoard from "./rankingBoard";
import roseChart from "./roseChart";
import scrollBoard from "./scrollBoard";
import scrollRiskpoint from "./risk_point";
import BottomCharts from "./BottomCharts";
import { bibaseData } from "../../../assets/request/api";

export default {
  name: "DataView",
  components: {
    topHeader,
    digitalFlop,
    rankingBoard,
    roseChart,
    scrollBoard,
    scrollRiskpoint,
    BottomCharts,
  },
  data() {
    return {
      key: 1,
      digitalindex: 1,
      dangerCateColor: [],
      personnlList: [],
      secDepartments: [],
      digitalFlopData: [
        {
          title: "学生数量",
          number: {
            number: [10640],
            content: "{nt}",
            textAlign: "right",
            style: {
              fill: "#4d99fc",
              fontWeight: "bold",
            },
          },
          unit: "名",
        },
        {
          title: "教师数量",
          number: {
            number: [559],
            content: "{nt}",
            textAlign: "right",
            style: {
              fill: "#4d99fc",
              fontWeight: "bold",
            },
          },
          unit: "名",
        },
        {
          title: "保安数量",
          number: {
            number: [26],
            content: "{nt}",
            textAlign: "right",
            style: {
              fill: "#4d99fc",
              fontWeight: "bold",
            },
          },
          unit: "名",
        },
        {
          title: "风险点数量",
          number: {
            number: [336],
            content: "{nt}",
            textAlign: "right",
            style: {
              fill: "#4d99fc",
              fontWeight: "bold",
            },
          },
          unit: "个",
        },
        {
          title: "安保设备",
          number: {
            number: [230],
            content: "{nt}",
            textAlign: "right",
            style: {
              fill: "#4d99fc",
              fontWeight: "bold",
            },
          },
          unit: "个",
        },
        {
          title: "消防设施",
          number: {
            number: [248],
            content: "{nt}",
            textAlign: "right",
            style: {
              fill: "#4d99fc",
              fontWeight: "bold",
            },
          },
          unit: "个",
        },
        {
          title: "安防监控",
          number: {
            number: [562],
            content: "{nt}",
            textAlign: "right",
            style: {
              fill: "#4d99fc",
              fontWeight: "bold",
            },
          },
          unit: "个",
        },
        {
          title: "人脸闸机",
          number: {
            number: [0],
            content: "{nt}",
            textAlign: "right",
            style: {
              fill: "#4d99fc",
              fontWeight: "bold",
            },
          },
          unit: "个",
        },
        {
          title: "车辆道闸",
          number: {
            number: [2],
            content: "{nt}",
            textAlign: "right",
            style: {
              fill: "#4d99fc",
              fontWeight: "bold",
            },
          },
          unit: "个",
        },
      ],
      department_config: {
        header: [],
        data: [],
        index: true,
        columnWidth: [50],
        align: ["center"],
        rowNum: 6,
        headerBGC: "#1981f6",
        headerHeight: 0,
        oddRowBGC: "rgba(0, 44, 81, 0.8)",
        evenRowBGC: "rgba(10, 29, 50, 0.8)",
      },
    };
  },
  created() {
    this.way_Data();
  },
  methods: {
    way_Data() {
      bibaseData().then((res) => {
        let {
          dagerList,
          highList,
          user_type,
          device,
          danger,
          dangerCateColor,
          dangerLevelList,
          personnlList,
          secDepartments,
        } = res.data;
        console.log(res.data);
        let config_list = [];
        dagerList.map((item) => {
          config_list.push([item.name, item.value]);
        });

        this.department_config.data = config_list;
        this.department_config = { ...this.department_config };

        let user_List = Object.values(user_type);
        let device_List = Object.values(device);
        let List = [
          user_List[1],
          user_List[0],
          user_List[2],
          danger,
          ...device_List,
        ];
        let top_List = this.digitalFlopData;

        top_List.map((item, index) => {
          item.title = List[index].name;
          item.number.number = [List[index].total];
        });
        this.digitalFlopData = top_List;
        //重新渲染
        this.digitalindex++;
        console.log(this.digitalFlopData);
        dangerCateColor.unshift({
          cate_name: "风险等级占比",
          data: dangerLevelList,
        });
        this.dangerCateColor = dangerCateColor;
        this.personnlList = personnlList;
        this.secDepartments = secDepartments;
      });
    },
  },

  mounted() {
    var _this = this;
    window.addEventListener("resize", function (e) {
      _this.$nextTick(() => {
        _this.key++;

        // console.log(_this.personnlList);
        // _this.personnlList = [];
        // _this.personnlList = _this.personnlList;

        _this.way_Data();

        //刷新页面，重新计算高度
        // window.location.reload();
      });
    });
  },
};
</script>

<style lang="less" scoped>
#data-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  #dv-full-screen-container {
    background-image: url("../../../assets/img/bg2.png");
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
  }

  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .block-left-right-content {
    flex: 1;
    display: flex;
    margin-top: 20px;
  }

  .block-top-bottom-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    // padding-right: 20px;
    overflow: hidden;
  }

  .block-top-content {
    height: 43%;
    display: flex;
    flex-grow: 0;
    box-sizing: border-box;
    padding-bottom: 20px;
  }
}

#digital-flop {
  position: relative;
  height: 15%;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(6, 30, 93, 0.5);

  .dv-decoration-10 {
    position: absolute;
    width: 95%;
    left: 2.5%;
    height: 5px;
    bottom: 0px;
  }

  .digital-flop-item {
    width: 11%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 3px solid rgb(6, 30, 93);
    border-right: 3px solid rgb(6, 30, 93);
  }

  .digital-flop-title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .digital-flop {
    display: flex;
  }

  .unit {
    margin-left: 10px;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    padding-bottom: 13px;
  }
}

#scroll-board {
  width: 30%;
  box-sizing: border-box;
  margin-right: 20px;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, 0.5);
  display: flex;
  flex-direction: column;
}
.ranking-board-title {
  padding-left: 10px;
  font-weight: bold;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 20px;
}
</style>
