<template>
  <div>
    <div class="table_sty">
      <div class="froms" v-if="!id">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form :model="formLabelAlign" class="from">
            <el-form-item label="操作人：" label-width="75px" style="width: 300px;">
              <el-input placeholder="请输入姓名" size="mini" class="form-inp" v-model="formLabelAlign.name"></el-input>
            </el-form-item>

            <el-form-item label-width="90px" label="时间选择：">
              <div>
                <el-date-picker style="width: 200px;" value-format="yyyy-MM-dd" v-model="formLabelAlign.bengin_time"
                  type="date" placeholder="开始日期" @change="DateChange()">
                </el-date-picker>
                至
                <el-date-picker style="width: 200px;" value-format="yyyy-MM-dd" v-model="formLabelAlign.end_time"
                  type="date" placeholder="结束日期" :picker-options="pickerOptions">
                </el-date-picker>
              </div>
            </el-form-item>

            <!-- <el-form-item label="分类：">
              <el-select v-model="formLabelAlign.type" placeholder="请选择分类">
                <el-option 
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
          </el-form>
          <div class="search_button">
            <div>
              <el-button @click="way_tableData(true)" type="primary" icon="el-icon-search">查询</el-button>
              <el-button @click="on_reset" icon="el-icon-refresh-left">重置</el-button>
            </div>
          </div>
        </div>
      </div>

      <div class="tab_sty" style="margin-top: 20px;">
        <div class="add-sty" v-if="id">
          <p>管控日志</p>
          <div>
            <div style="display: inline-block; margin-right: 10px"
              v-if="(url_type == 'hdanger' || url_type == 'cases') && id">
              <el-button v-if="operation_status == 0 ||
                operation_status == 1 ||
                operation_status == 5 ||
                operation_status == 6 ||
                operation_status == 7
                " type="primary" @click="on_assess">指派</el-button>

              <el-button v-if="operation_status == 1 ||
                operation_status == 2 ||
                operation_status == 3 ||
                operation_status == 5
                " type="primary" @click="on_waypop(1)">整改</el-button>

              <el-button v-if="operation_status == 4 ||
                operation_status == 5 ||
                operation_status == 3
                " type="primary" @click="on_waypop(2)">验收</el-button>
            </div>

            <!-- <el-button
              v-else
              icon="el-icon-plus"
              type="primary"
              @click="on_newly"
              >添加管控</el-button
            > -->
          </div>
        </div>
        <div class="block" v-if="(url_type == 'hdanger' || url_type == 'cases') && id">
          <el-timeline>
            <el-timeline-item v-for="(item, index) in table_List" :key="index"
              :timestamp="item.add_time + ' ' + item.nickname" placement="top">
              <el-card>
                <!-- <div class="message">
                  <p>名称：</p>
                  <h4>{{ item.name }}</h4>
                </div> -->
                <div class="message">
                  <p>类型：</p>
                  <h4>{{ item.from_name }}</h4>
                </div>

                <div class="message" v-if="item.form_images">
                  <p>图片：</p>

                  <div class="imgs">
                    <div v-for="(el, ins) in $tools.img_split(item.form_images)" :key="ins">
                      <el-image :src="el" alt="" :preview-src-list="[el]" />
                    </div>
                  </div>
                </div>

                <div class="message" v-if="item.form_vedio">
                  <p>视频：</p>
                  <video class="upload-sty" controls="controls" :src="item.form_vedio"></video>
                </div>

                <div class="message">
                  <p>说明：</p>
                  <h4>{{ item.form_detail }}</h4>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>

        <div v-else>
          <div>
            <el-table highlight-current-row :data="table_List" :header-cell-style="{ background: '#f8f8f9' }"
              style="flex: 1">
              <el-table-column label="序号" type="index" :index="indexMethod"></el-table-column>
              <el-table-column prop="title" :label="`${url_type == 'regulation' ? '制度' : '管控'}标题`"></el-table-column>
              <el-table-column prop="from_name" label="管控类型`"></el-table-column>

              <el-table-column prop="nickname" :label="`${table_head}人`"></el-table-column>
              <el-table-column prop="add_time" :label="`${table_head}时间`"></el-table-column>

              <el-table-column prop="name" label="操作">
                <template slot-scope="scope">
                  <el-link type="primary" class="list_sty" @click="on_particulars(scope.row)">查看更多</el-link>

                  <el-link v-if="url_type == 'personnel'" class="list_sty" type="primary"
                    @click="on_details(scope.row.id)">详情</el-link>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="paging">
            <el-pagination @current-change="way_page" :page-size="page_size" layout="prev, pager, next, jumper"
              :total="total" :current-page="page">
            </el-pagination>
          </div>
        </div>
      </div>

      <el-dialog show-close :title="rowitem.name" :visible.sync="drawer" width="30%">
        <div class="drawer">
          <div v-if="drawer_type == 0" class="drawer-more">
            <div>
              <p class="p1">{{ table_head }}人：</p>
              <p class="p2">{{ rowitem.nickname }}</p>
            </div>

            <div>
              <p class="p1">{{ table_head }}时间：</p>
              <p class="p2">{{ rowitem.add_time }}</p>
            </div>

            <div>
              <p class="p1">管控详情：</p>
              <p class="p2">{{ rowitem.form_detail }}</p>
            </div>

            <div v-if="rowitem.form_is_normal != 0">
              <p class="p1">管控点状态：</p>
              <el-tag :type="rowitem.form_is_normal == 1 ? 'success' : 'danger'">{{ rowitem.is_normal }}</el-tag>
            </div>

            <div v-if="rowitem.form_images">
              <p class="p1">图片：</p>
              <div class="imgsList">
                <el-image :z-index="4000" v-for="(item, index) in com_imgList" :key="index" :src="item"
                  :preview-src-list="[item]"></el-image>
              </div>
            </div>

            <div v-if="rowitem.form_vedio">
              <p class="p1">视频：</p>
              <video class="upload-sty" controls="controls" :src="rowitem.form_vedio"></video>
            </div>
          </div>
        </div>
      </el-dialog>

      <!-- 添加管控 -->

      <el-dialog show-close title="新增管控" :visible.sync="control_show" center>
        <newlycontrol v-if="control_show" ref="newlycontrol" :refbool.sync="control_show"
          @way_tableData="way_tableData" />
      </el-dialog>
      <!-- 自定义表单管控 -->

      <el-dialog show-close title="新增管控" :visible.sync="Custom_show" center>
        <CustomFromanalysis v-if="Custom_show" ref="CustomFromanalysis" :frmcode="frmcode" :formvalue="formvalue"
          :refbool.sync="Custom_show" @way_tableData="way_tableData" />
      </el-dialog>

      <!-- 审批详情 -->
      <el-dialog :title="title" show-close :visible.sync="examine_show" center>
        <examineparticulars v-if="examine_show" ref="examineparticulars" :refbool.sync="examine_show"
          @way_tableData="way_tableData" />
      </el-dialog>

      <!-- 指派弹窗 -->
      <el-dialog title="指派" :visible.sync="assess_pop" show-close center>
        <el-form label-width="150px" ref="formName" :model="assess_Data">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item :label="`是否${typName}：`" prop="status"
                :rules="[{ required: true, message: `请选择是否${typName}` }]">
                <el-select v-model="assess_Data.status" :placeholder="`请选择是否${typName}`">
                  <el-option v-for="item in enable_list" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="截止日期：" prop="end_time" :rules="[{ required: true, message: '请输入截止日期' }]">
                <el-input onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="截止日期"
                  v-model.number="assess_Data.end_time" class="form-inp">
                  <template slot="append">天</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="部门：" prop="department_id" :rules="[{ required: true, message: '请选择部门' }]">
                <Bepartment ref="assess_Bepartment" class="form-inp" v-model="assess_Data.department_id" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任务表单：" prop="scheme_id" :rules="[{ required: true, message: '请选择任务表单' }]">
                <el-select class="form-inp" v-model="assess_Data.scheme_id" placeholder="任务表单：">
                  <el-option v-for="item in scheme_list" :key="item.value" :label="item.task_name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="分类：" prop="cate_id" :rules="[{ required: true, message: '请选择分类' }]">
                <el-cascader class="form-inp" :value="assess_Data.cate_id" v-model="assess_Data.cate_id" :options="cate"
                  :props="{
                    checkStrictly: true,
                    label: 'name',
                    children: 'items',
                    value: 'id',
                  }" @change="assessChange"></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="评估等级：" prop="level_color" :rules="[{ required: true, message: '请选择评估等级' }]">
                <el-select v-model="assess_Data.level_color" placeholder="请选择评估等级">
                  <el-option v-for="item in level_color_list" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="考核分数">
                <el-input type="number" placeholder="考核分数" size="mini" class="form-inp"
                  v-model="assess_Data.score"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="任务执行对象：" prop="checkedCities" :rules="[{ required: true, message: '请选择任务执行对象' }]">
                <el-checkbox-group v-model="assess_Data.checkedCities">
                  <el-checkbox v-for="city in office_position" :label="city" :key="city.id">{{ city.name }}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div style="text-align: center; margin: 20px 0">
          <el-button @click="on_preserve('formName')" type="primary">指派</el-button>
          <el-button @click="assess_pop = false">返回</el-button>
        </div>
      </el-dialog>

      <!-- 整改弹窗 -->
      <el-dialog show-close title="整改" :visible.sync="newly_show" center>
        <el-form ref="formName" label-width="180px" :model="newly_From">
          <el-form-item label="整改完成：">
            <el-switch v-model="newly_From.form_is_normal" active-text="是" inactive-text="否" active-value="1"
              inactive-value="2">
            </el-switch>
          </el-form-item>
          <el-form-item label="详情：">
            <el-input type="textarea" placeholder="请输入详情" size="mini" class="form-textarea"
              v-model="newly_From.form_detail"></el-input>
          </el-form-item>
          <el-form-item label="现场图片：">
            <uploadingimg v-model="newly_From.img_List" />
          </el-form-item>
          <el-form-item label="视频或录音：">
            <uploadingVideo v-model="newly_From.video_List" />
          </el-form-item>
        </el-form>
        <div style="text-align: center; margin: 20px 0">
          <el-button @click="on_newlypreserve('formName')" type="primary">确定</el-button>
          <el-button @click="newly_show = false">返回</el-button>
        </div>
      </el-dialog>

      <!-- 验收弹窗 -->
      <el-dialog title="验收" show-close :visible.sync="check_show" center width="800px" @closed="closed_check">
        <el-form ref="check_formName" label-width="100px" :model="check_From">
          <el-form-item label="是否通过：">
            <el-switch v-model="check_From.is_pass" active-text="是" inactive-text="否" active-value="1" inactive-value="2">
            </el-switch>
          </el-form-item>
          <el-form-item label="验收报告：">
            <el-input type="textarea" placeholder="请输入验收报告" size="mini" class="form-textarea"
              v-model="check_From.form_detail"></el-input>
          </el-form-item>

          <div style="text-align: center; margin: 20px 0">
            <el-button @click="on_checkserve('check_formName')" type="primary">确定</el-button>
            <el-button @click="check_show = false">返回</el-button>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  ay_control,
  hdangercheckpass,
  ay_addrecorder,
  hdangerestimateInfo,
  ay_recorderSave,
  hdangerestimateSave,
  casesestimateInfo,
  casesestimateSave,
  casescheckpass,
} from "../assets/request/api";
import examineparticulars from "./control/examine_particulars.vue";
import newlycontrol from "./control/newly_control.vue";
import CustomFromanalysis from "../components/custom_From/custom_Fromanalysis.vue";
import uploadingimg from "../components/uploading/uploading_img.vue";
import uploadingVideo from "../components/uploading/uploading_Video.vue";
import Bepartment from "../components/Bepartment";
export default {
  name: "Soldier_brothersstanding",
  components: {
    examineparticulars,
    newlycontrol,
    CustomFromanalysis,
    Bepartment,
    uploadingimg,
    uploadingVideo,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    url_type: {
      type: String,
    },
    operation_status: {
      type: Number,
    },
  },
  data() {
    return {
      table_head: "管控",
      typName: "",
      cate: [],

      enter_type: "",
      drawer_type: 0,
      drawer: false,
      table_List: [],
      //条数
      page_size: 10,
      //页数
      page: 1,
      //总条数
      total: 0,
      formLabelAlign: {
        name: "",
        bengin_time: "",
        end_time: "",
      },
      timeData: [],
      //展示详情
      Data: "",
      type: "",
      //更多
      rowitem: {},
      com_imgList: [],
      //审批详情
      title: "",
      examine_show: false,
      //添加管控
      control_show: false,
      //自定义表单管控
      Custom_show: false,
      frmcode: "",
      formvalue: "",
      //统计周期结束时间的最低选择时间
      pickerOptions: {},

      //指派操作
      assess_pop: false,
      assess_Data: {
        id: "",
        level_color: "",
        cate_id: "",
        score: 0,
        status: "",
        department_id: "",
        scheme_id: "",
        checkedCities: [],
        end_time: "",
      },
      enable_list: [],
      level_color_list: [],
      level_list: [],
      scheme_list: [],
      office_position: [],
      cate: [],
      //整改
      newly_show: false,
      newly_From: {
        source_id: "",
        form_detail: "",
        video_List: [],
        img_List: [],
        form_is_normal: "1",
      },
      //验收
      check_show: false,
      check_From: {
        source_id: "",
        is_pass: "1",
        form_detail: "",
      },
    };
  },
  created() {
    let { type, control_id } = this.$route.query;
    this.typName = this.url_type == "hdanger" ? "隐患" : "事件";
    if (this.url_type == "regulation") {
      this.table_head = "填报";
    }
    //判断是任务还是管控
    if (type == "task") {
      this.on_newly();
      this.enter_type = type;
    } else if (
      type == "control" &&
      this.url_type != "hdanger" &&
      this.url_type != "cases"
    ) {
      this.formLabelAlign.id = control_id;
      this.enter_type = type;
    }
    if (this.id) {
      this.formLabelAlign.source_id = this.id;
    }
    this.way_tableData();
  },

  methods: {
    on_details(id) {
      this.$router.push({
        path: "/security/focus_particulars",
        query: {
          id: id,
        },
      });
    },
    //统计周期结束日期禁用
    DateChange(type) {
      if (!type) {
        this.formLabelAlign.end_time = "";
      }
      let that = this;
      this.pickerOptions = {
        //日期禁用
        disabledDate(date) {
          return that.$tools.Time_todetermine(
            date,
            that.formLabelAlign.bengin_time
          );
        },
      };
    },
    on_particulars(row) {
      if (
        row.from_type == "flow_dangerous" ||
        row.from_type == "flow_activity" ||
        row.from_type == "flow_personnel" ||
        row.from_type == "flow_case" ||
        row.from_type == "flow_regulation" ||
        row.from_type == "flow_eduction"
      ) {
        let { name, instance_id } = row;
        this.title = name;
        this.examine_show = true;
        this.$nextTick(function () {
          this.$refs.examineparticulars.way_show(instance_id);
        });
      } else {
        this.rowitem = row;
        if (row.form_images) {
          this.com_imgList = row.form_images.split(",");
        }
        console.log(this.com_imgList);
        this.drawer = true;
      }

      // let type = row.from_type;

      // let id = row.info_id;
      // console.log(id);
      // let url = "";

      // switch (type) {
      //   case "flow_activity":
      //   case "activity_recorder":
      //     url = "/api/activity/saveInfo";
      //     break;
      //   case "dangerous_recorder":
      //   case "flow_dangerous":
      //     url = "/api/hdanger/detail";
      //     break;
      //   case "personnel_recorder":
      //   case "flow_personnel":
      //     url = "/api/personnel/detail";
      //     break;
      //   case "eduction_recorder":
      //   case "flow_eduction":
      //     url = "/api/Eduction/detail";
      //     break;
      //   case "regulation_recorder":
      //   case "flow_regulation":
      //     url = "/api/regulation/detail";
      //     break;
      //   case "hdanger_recorder":
      //   case "flow_hdanger":
      //     url = "/api/hdanger/detail";
      //     break;
      //   case "case_recorder":
      //   case "flow_case":
      //     url = "/api/cases/detail";
      //     break;
      // }

      // way_flowcode_url(url, { id }).then((res) => {
      //   console.log(res);
      //   this.type = type;
      //   this.Data = res.data.data;
      //   console.log(this.Data);
      //   this.drawer = true;
      // });
    },
    //新增管控
    on_newly() {
      let that = this;
      let { url_type, id } = that;
      ay_addrecorder(url_type, { id }).then((res) => {
        console.log(res);
        let { data } = res;
        if (data.mode == 0) {
          this.control_show = true;
          that.$nextTick(function () {
            that.$refs.newlycontrol.url_type = url_type;
            that.$refs.newlycontrol.formLabelAlign.source_id = id;
          });
        } else if (data.mode == 1) {
          let { frmcode, formvalue, info } = data;
          that.frmcode = frmcode;
          that.formvalue = formvalue;
          this.Custom_show = true;
          this.$nextTick(function () {
            that.$refs.CustomFromanalysis.way_Datadispose(info.id);
          });
        }
      });
    },
    on_reset() {
      let { formLabelAlign } = this;
      formLabelAlign.name = "";
      formLabelAlign.bengin_time = "";
      formLabelAlign.end_time = "";
      this.timeData = [];
      this.way_tableData(true);
    },
    indexMethod(index) {
      index = index + 1 + (this.page - 1) * this.page_size;
      return index;
    },
    way_tableData(isfirst) {
      if (isfirst) {
        this.page = 1;
      }
      let { formLabelAlign, page, page_size, url_type } = this;
      formLabelAlign.page = page + "";
      formLabelAlign.page_size = page_size;
      ay_control(url_type, formLabelAlign).then((res) => {
        let { data, total, catesList } = res.data;
        this.cate = catesList;
        this.total = total;
        this.table_List = data;
        if (this.enter_type == "control") {
          this.on_particulars(data[0]);
        }
      });
    },
    //分页
    way_page(val) {
      this.page = val;
      this.way_tableData();
    },

    on_waypop(type) {
      let that = this;
      let { url_type, id } = that;
      if (type == 1) {
        ay_addrecorder(url_type, { id }).then((res) => {
          console.log(res);
          let { data } = res;
          if (data.mode == 0) {
            this.newly_From.source_id = id;
            this.newly_show = true;
          } else if (data.mode == 1) {
            let { frmcode, formvalue, info } = data;
            that.frmcode = frmcode;
            that.formvalue = formvalue;
            this.Custom_show = true;
            this.$nextTick(function () {
              that.$refs.CustomFromanalysis.way_Datadispose(info.id);
            });
          }
        });
      } else if (type == 2) {
        this.check_From.source_id = id;
        this.check_show = true;
      }
    },
    assessChange(event) {
      let events = event[event.length - 1];
      this.assess_Data.cate_id = events;
    },

    //验收清除
    closed_check() {
      this.check_From.source_id = "";
      this.check_From.is_pass = "1";
      this.check_From.form_detail = "";
    },

    on_assess() {
      let id = this.id;
      let url = hdangerestimateInfo;
      if (this.url_type == "cases") url = casesestimateInfo;
      url({ id }).then((res) => {
        let {
          level_color_list,
          info,
          level_list,
          enable_list,
          scheme_list,
          office_position,
        } = res.data;
        let { score, level_color, status, department_id, cate_id, end_time } =
          info;
        this.level_color_list = level_color_list;
        this.level_list = level_list;
        this.enable_list = enable_list;
        this.scheme_list = scheme_list;
        this.office_position = office_position;
        this.assess_pop = true;
        this.assess_Data.scheme_id = scheme_list[0].id;
        this.assess_Data.id = id;
        this.assess_Data.score = score;
        this.assess_Data.status = status;
        this.assess_Data.end_time = end_time;
        this.assess_Data.level_color = level_color || "";
        this.assess_Data.cate_id = cate_id || "";
        if (department_id) {
          this.assess_Data.department_id = department_id;
          this.$nextTick(function () {
            this.$refs.assess_Bepartment.bepartment_List = department_id;
          });
        }
      });
    },

    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = hdangerestimateSave;
          if (this.url_type == "cases") url = casesestimateSave;
          let { assess_Data } = this;
          let From_Data = JSON.parse(JSON.stringify(assess_Data));
          From_Data.office_position = assess_Data.checkedCities
            .map((item) => item.id)
            .join(",");
          delete From_Data.checkedCities;
          From_Data.end_time = this.$tools.getNextDate(From_Data.end_time);
          url(From_Data).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.way_tableData();
              this.assess_pop = false;
            }
          });
        } else {
          return false;
        }
      });
    },

    //整改保存
    on_newlypreserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let newly_From = JSON.parse(JSON.stringify(this.newly_From));
          newly_From.vedio = newly_From.video_List.join(",");
          newly_From.images = newly_From.img_List.join(",");
          delete newly_From.video_List;
          delete newly_From.img_List;
          let url_type = this.url_type;
          ay_recorderSave(url_type, newly_From).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              setTimeout(function () {
                that.newly_show = false;
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },

    //验收保存
    on_checkserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { check_From } = this;
          let url = hdangercheckpass;
          if (this.url_type == "cases") url = casescheckpass;
          url(check_From).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.way_tableData();
              setTimeout(function () {
                that.check_show = false;
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.drawer-more {
  &>div {
    font-size: 14px;
    margin-bottom: 20px;
    display: flex;
    font-size: 16px;
    align-items: center;
  }

  .p1 {
    width: 100px;
    margin-right: 10px;
  }
}

.imgsList {
  display: flex;

  &>div {
    width: 120px;
    height: 120px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}

.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}

.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.form-inp {
  width: 200px;
}

.paging {
  text-align: center;
  padding: 10px;
}

.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 10px;
  }

  p {
    display: flex;
    align-items: center;
  }
}

.span1 {
  font-size: 14px;
  color: #000000;
}

.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}

.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}

.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}

.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.list_sty {
  margin-right: 10px;
}

.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}

.entirety {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.swiper-sty {
  flex: 1;
}

.tab {
  background-color: #ffffff;
  display: flex;
  border-bottom: 1px solid #f0f0f0;
}

.tab-z {
  height: 80px;
  line-height: 80px;
  text-align: center;
  flex: 1;
  color: #6e747a;
}

.pitch {
  position: relative;
  color: #3e4650;
}

.pitch:before {
  border-radius: 4px;
  content: "";
  position: absolute;
  bottom: 0px;
  width: 20%;
  height: 8px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0788ff;
}

.title-name {
  font-size: 35px;
  color: #2d2d2d;
}

.title-time {
  font-size: 20px;
  color: #c3c3c3;
}

.title-content {
  margin: 10px 0;
  font-size: 25px;
  color: #c3c3c3;
}

.describe-name {
  display: flex;
  align-items: center;
}

.describe-name view {
  margin-left: 10px;
  font-size: 28px;
  color: #2d2d2d;
}

.describe-name image {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.describe-state {
  font-size: 28px;
  color: #5abf97;
}

.describe {
  padding: 30px;
}

.describe-title {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #f1f1f1;
}

.describe-title>view {
  color: #333333;
}

.describe-xx {
  padding: 20px 0;
  color: #8799a3;
  border-bottom: 1px solid #f1f1f1;
}

.imgs {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.imgs>image {
  width: 23%;
  height: 150px;
  margin-bottom: 10px;
  margin-right: 20px;
  margin-bottom: 20px;

  /* border-radius:8px; */
}


.iconsty {
  color: #0073ff !important;
}

.iconsty text {
  color: #0073ff !important;
}

.all {
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 20px;
  background-color: #ffffff;
  margin-top: 20px;
}

.titles {
  padding: 20px;
  font-weight: bold;
  color: #1cbbb4;
  border-bottom: 1px solid #f1f1f1;
}

.BUTT {
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid #f1f1f1;
}

.radio-text {
  margin-left: 10px;
}

.textname {
  padding: 0 40px;
  margin-top: 10px;
  font-weight: 25px;
}

.add-sty {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  &>p {
    font-weight: bold;
    color: #409eff;
    font-size: 16px;
  }
}

.message {
  display: flex;
  font-size: 15px;
  color: #28307c;
  margin-top: 10px;

  .p1 {
    color: #465269;
  }
}

.imgs {
  display: flex;

  .el-image {
    width: 120px;
    height: 120px;
    margin-right: 10px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.el-col-12 {
  height: 51px;
}
</style>
