<template>
  <div id="ranking-board">
    <div class="ranking-board-title">各二级部门人数分布</div>
    <dv-scroll-board :config="config" />
  </div>
</template>

<script>
export default {
  name: "RankingBoard",
  props: {
    secDepartments: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    // secDepartments: function (newValue, oldVal) {
    //   this.config.data = newValue;
    //   this.config = { ...this.config };
    // },
    // deep: true,
  },
  data() {
    return {
      config: {
        header: ["时间", "病害信息", "数量", "标段"],
        data: [
 
          ["工商管理学院", 4],
          ["财会金融学院", 3],
          ["文化艺术学院", 2],
          ["商贸流通学院", 4],
          ["梦想创业学院", 1],
          ["马克思主义学院", 5],
          ["继续教育学院", 6],
          ["办公室", 4],
          ["组织部", 3],
          ["图书信息中心", 1],
          ["现代职业技术教育研究所", 1],
          ["工会办公室", 4],
            ["工商管理学院", 4],
          ["财会金融学院", 3],
          ["文化艺术学院", 2],
          ["商贸流通学院", 4],
          ["梦想创业学院", 1],
          ["马克思主义学院", 5],
          ["继续教育学院", 6],
          ["办公室", 4],
          ["组织部", 3],
          ["图书信息中心", 1],
          ["现代职业技术教育研究所", 1],
          ["工会办公室", 4],
     
        ],
        columnWidth: [],
        align: ["center"],
        rowNum: 14,
        headerBGC: "#1981f6",
        headerHeight: 0,
        oddRowBGC: "rgba(0, 44, 81, 0.8)",
        evenRowBGC: "rgba(10, 29, 50, 0.8)",
      },
    };
  },
};
</script>

<style lang="less" scoped>
#ranking-board {
  width: 20%;
  box-shadow: 0 0 3px blue;
  display: flex;
  flex-direction: column;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, 0.5);
  box-sizing: border-box;
  padding: 0px 30px;
  margin-left: 20px;
  .ranking-board-title {
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  .dv-scroll-ranking-board {
    flex: 1;
  }
}
</style>
